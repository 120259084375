import React, { useState, useEffect, memo } from 'react'
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Select,
  Switch,
  Upload,
  UploadFile,
  Divider
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { code, typeLabel, ListItem } from './index'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { Rule } from 'antd/es/form'
import { useSelector } from 'react-redux'
import { getRateWithUSDTSelector, Rate } from '@/store/slice/rate'
import { getTronList, selectAll } from '@/store/slice/tron'
import { useAppDispatch } from '@/store'
import config from '@/config'
import ImagePreview from '@/components/ImagePreview'
import * as api from './api'

interface Props {
  open: boolean
  codes: Array<keyof typeof typeLabel>
  record?: ListItem
  onCancel: () => void
  onSuccess: (data: ListItem) => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, record, codes, onCancel, onSuccess } = props
  const [type, setType] = useState<keyof typeof typeLabel>()
  const [title, setTitle] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string>()

  const dispatch = useAppDispatch()
  const tronList = useSelector(selectAll)
  const rateList = useSelector(getRateWithUSDTSelector)

  const [form] = Form.useForm()
  const filesRules: Rule[] = [
    {
      validator (rule, value) {
        const fileList: UploadFile[] = value || []
        const promises = fileList.map((item: UploadFile) => {
          if (item.status === 'success') return Promise.resolve()
          const imageLimitSize = 2 * 1024 * 1024 // 字节
          if (/image/.test(item.type as string) && (item.size as number) > imageLimitSize) {
            return Promise.reject(new Error('图片必须小于2M'))
          }
          return Promise.resolve()
        })

        return Promise.all(promises)
      }
    }
  ]

  useEffect(toggleVisible, [open, form, record, dispatch])
  useEffect(getTronListFn, [dispatch, tronList.length])

  function toggleVisible () {
    let fileList: UploadFile[] = []

    if (open && record) {
      if (record.img) {
        fileList = [{
          uid: record.img,
          url: `${config.cdnServer}/payment/${record.img}`,
          name: record.img,
          status: 'success'
        }]
      }
      setType(record.type)
      form.setFieldsValue({
        ...record,
        files: fileList.length ? fileList : undefined
      })
    }

    setTitle(record ? '编辑' : '添加')
  }

  function resetForm () {
    setType(undefined)
    form.resetFields()
  }

  // 选择的图片变化
  // const uploadOnChange: UploadProps['onChange'] = (info) => {
  //   const { file, fileList } = info
  //   if (file.status === 'removed') {
  //     URL.revokeObjectURL(file.url as string)
  //     if (/_uploaded$/.test(file.uid)) {
  //       setRemoveFile(file.name)
  //     }
  //   }
  //   setFileList(fileList)
  // }

  // 获取钱包列表
  function getTronListFn () {
    !tronList.length && dispatch(getTronList())
  }

  // 图片预览
  function onPreview (file: UploadFile) {
    let url: string
    if (file.status === 'success') {
      url = file.url!
    } else {
      url = URL.createObjectURL(file.originFileObj as Blob)
    }

    setPreviewUrl(url)
  }

  function onFinish (values: Record<string, any>) {
    const { files, ...field } = values
    const fileList: UploadFile[] = files || []
    const promise = values._id ? api.update : api.create
    const formData = new FormData()

    if (values._id && record?.img) {
      const length = fileList.filter(i => i.status === 'success').length
      field.removeFile = length ? undefined : record.img
    }

    Object.keys(field).forEach(key => {
      const value = JSON.stringify(field[key])
      value && formData.set(key, value)
    })

    fileList.forEach((item: UploadFile) => {
      if (item.status === 'success') return
      formData.set('payment', item.originFileObj as Blob)
    })

    setLoading(true)
    promise(formData).then(({ success, data }) => {
      if (success) {
        onCancel()
        onSuccess(data)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title={title}
      centered={isMobile}
      className="common-modal"
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      onOk={() => form.submit()}
      onCancel={onCancel}
      afterClose={resetForm}>
      <Form
        name="payment"
        form={form}
        layout="vertical"
        initialValues={{ enable: true }}
        onFinish={onFinish}>
        <Form.Item name="_id" noStyle>
          <Input hidden type="text"/>
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="收款名称"
              rules={requiredInputRule}>
              <Input maxLength={16} placeholder="请输入"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="code"
              label="编号"
              rules={requiredInputRule}>
              {/* <Input maxLength={20} placeholder="请输入"/> */}
              <Select options={code} placeholder="请选择"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name="currency"
              label="货币"
              rules={requiredInputRule}>
              <Select
                options={rateList}
                showSearch
                fieldNames={{ label: 'key', value: 'key' }}
                placeholder="请选择"
                onChange={(value, option) => form.setFieldValue('rate', (option as Rate)?.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rate" label="汇率" rules={requiredInputRule}>
              <InputNumber pattern="\d*" min={0.001} controls={false} placeholder="请输入"/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="type"
          label="收款方式"
          rules={requiredInputRule}>
          <Select placeholder="请选择" onChange={setType}>
            {
              codes.map(item => (
                <Select.Option key={item} value={item}>
                  {typeLabel[item as keyof typeof typeLabel]}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        {
          type &&
          (
            type === 'usdt'
              ? <Form.Item name="account" label="收款钱包" rules={requiredInputRule}>
                <Select placeholder="请选择">
                  {
                    tronList.map(item => (
                      <Select.Option
                        key={item._id}
                        value={item.base58}>
                        <span>{item.name}</span>
                        <Divider type={'vertical'}/>
                        <span>USDT：{item.usdt}</span>
                        <Divider type={'vertical'}/>
                        <span>TRX：{item.trx}</span>
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
              : <Form.Item name="account" label="收款账号" rules={requiredInputRule}>
                <Input maxLength={34} placeholder="请输入"/>
              </Form.Item>
          )
        }
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="sort" label="排序" rules={requiredInputRule}>
              <InputNumber pattern="\d*" min={0} step={1} precision={0} placeholder="请输入"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="enable" label="状态" rules={requiredInputRule} valuePropName="checked">
              <Switch
                checkedChildren="启用"
                unCheckedChildren="禁用"/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="files"
          label="收款码"
          rules={filesRules}
          tooltip="数字货币收款方式，不用上传二维码，会自动根据收款地址生成二维码"
          valuePropName="fileList"
          getValueFromEvent={e => e?.fileList}>
          <Upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            onPreview={onPreview}>
            <div>
              <PlusOutlined/>
              <div>选择图片</div>
            </div>
          </Upload>
        </Form.Item>
      </Form>
      <ImagePreview url={previewUrl} setUrl={setPreviewUrl}/>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
