import React, { memo, useState } from 'react'
import { Form, Drawer, Space, Button, UploadFile, Row, Col, Radio } from 'antd'
import { requiredInputRule, useUserSelector } from '@/utils/hooks'
import { File } from '@/views/telegram/types'
import { suffix, SendMessageFormItem, MultiDelayFormItem } from './SendMessage'
import { UsernameFormItem } from '@/views/telegram/channel/AddModal'
import { uploadFile } from '@/api/common'
import FormAccountSelect from '@/views/telegram/FormAccountSelect'
import * as api from '@/views/task/api'

interface Props {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

interface FormValues {
  files: UploadFile[]
  photo: File[]
  delay: number
  maxDelay?: number
  footer: 'emoji' | 'letter' | 'nothing'
  message: string
  username: string
  delayUnit: 'seconds' | 'minute'
  previewLink: boolean
  splitEqually: boolean
}

const initialValues: Partial<FormValues> = {
  files: [],
  delayUnit: 'seconds',
  delay: 5,
  previewLink: true,
  splitEqually: true
}

const Index: React.FC<Props> = (props) => {
  const { open = false, onClose, onSuccess } = props
  const [loading, setLoading] = useState<boolean>(false)

  const user = useUserSelector()

  const [form] = Form.useForm()

  function upload (file?: UploadFile) {
    const params = {
      file: file?.originFileObj
    }

    if (!file) return Promise.resolve([])

    return uploadFile<File[]>(params).then(({ success, data, message }) => {
      if (success) return data
      return Promise.reject(message)
    })
  }

  function onFinish (values: FormValues) {
    const { files, username, splitEqually, ...rest } = values
    const params = {
      ...rest,
      targetList: username.split('\n'),
      accountDelay: 0
    }

    if (user.role.type === 'user') params.message += suffix

    setLoading(true)

    upload(files[0])
      .then(files => {
        if (files) params.photo = files
        const apiFn = splitEqually ? api.createSplitTasks : api.create
        const _params = {
          params,
          action: 'sendMessageByUsername'
        }
        return apiFn(_params)
      })
      .then(({ data, success }) => {
        if (success) {
          onClose()
          onSuccess()
        }
      })
      .finally(() => setLoading(false))
  }

  const DrawerFooter = <Space>
    <Button onClick={onClose}>取消</Button>
    <Button
      type={'primary'}
      loading={loading}
      onClick={form.submit}>提交</Button>
  </Space>

  return (
    <Drawer
      title={'批量私聊'}
      open={open}
      closable={false}
      placement="right"
      rootClassName={'drawer-720'}
      footerStyle={{ textAlign: 'right' }}
      forceRender
      footer={DrawerFooter}
      onClose={onClose}
      afterOpenChange={v => !v && form.resetFields()}>
      <Form
        name="sendByUsername"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center'
        }}
        onFinish={onFinish}>
        <FormAccountSelect form={form}/>
        <UsernameFormItem max={1000} form={form} label={'用户名'} showFilterText={false}/>
        <Row gutter={20}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <MultiDelayFormItem form={form}/>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="splitEqually"
              rules={requiredInputRule}
              label="分配方式"
              tooltip={'选中全部，所有选中账号都将私聊全部用户名，选中平分，选中账号将平分需私聊的用户名'}>
              <Radio.Group>
                <Radio value={false}>全部</Radio>
                <Radio value={true}>平分</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <SendMessageFormItem open={open} form={form}/>
      </Form>
    </Drawer>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
