import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 列表
export function all<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/all'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function getServerId<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/getServerId'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function batchUpdate<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/batchUpdate'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function syncServer<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/syncServer'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/server/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
