import React from 'react'
import { Descriptions, Modal, Tag } from 'antd'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { Target } from '@/views/telegram/types'
import { translate } from '@/utils/translate'

export interface AccessItem {
  id: string
  title?: string
  access_hash?: string
}

export type SuccessTag = boolean | string

export interface ResultItem {
  label: string
  value: SuccessTag | SuccessTag[]
}

interface PeerTypeOption extends DefaultOptionType {
  label: string
  value: Target
}

interface messageTypeOption extends DefaultOptionType {
  label: string
  value: Target | 'recent'
}

export const targetOptions: PeerTypeOption[] = [
  { label: '用户', value: 'peerUser' },
  { label: '群组', value: 'peerChannel' }
]

export const messageTypeOptions: messageTypeOption[] = [
  { label: '最近', value: 'recent' },
  { label: '用户', value: 'peerUser' },
  { label: '群组', value: 'peerChannel' }
]

export function resultModal (list: ResultItem[]) {
  let instance: any = Modal.info({
    width: 450,
    title: '操作结果',
    content: (
      <Descriptions size={'middle'} column={1}>
        {
          list.map((item, index) => {
            const { label, value } = item
            return <Descriptions.Item
              key={index}
              labelStyle={{ whiteSpace: 'nowrap' }}
              label={label}>
              {
                Array.isArray(value)
                  ? <>
                    <Tag color="success">
                      <span>成功&nbsp;</span>
                      {
                        value.filter(item => item === true || item === 'success').length
                      }
                    </Tag>
                    <Tag color="error">
                      <span>失败&nbsp;</span>
                      {
                        value.filter(item => item !== true && item !== 'success').length
                      }
                    </Tag>
                  </>
                  : value === true || value === 'success'
                    ? <Tag color="success">成功</Tag>
                    : <Tag color={typeof value === 'number' ? 'success' : 'error'}>{translate(String(value))}</Tag>
              }
            </Descriptions.Item>
          })
        }
      </Descriptions>
    ),
    onOk () {
      instance.destroy()
      instance = null
    }
  })
}
