import React, { memo, useEffect, useState } from 'react'
import { Modal, Form, Input, Radio, Select, Row, Col } from 'antd'
import { ListItem } from './index'
import { isMobile, requiredInputRule, useAuthsSelector } from '@/utils/hooks'
import * as api from './api'

interface Props {
  open: boolean
  record?: ListItem
  errorCode: Record<string, string>
  onCancel: () => void
  onSuccess: (data: ListItem) => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, record, errorCode, onCancel, onSuccess } = props
  const [loading, setLoading] = useState<boolean>(false)

  const auths = useAuthsSelector()

  const [form] = Form.useForm()
  const adminRemarks = Object.keys(errorCode).map(key => ({ label: errorCode[key], value: key }))

  useEffect(() => {
    open && form.setFieldsValue({ ...record, status: undefined })
  }, [open])

  function onFinish (values: Record<string, any>) {
    values.adminRemarks = values.adminRemarks && values.adminRemarks[0]

    setLoading(true)
    api.updateStatus(values).then(({ success, data }) => {
      if (success) {
        onCancel()
        onSuccess(data)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title="审核账单"
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={() => form.submit()}
      onCancel={onCancel}>
      <Form
        name="check"
        form={form}
        layout="vertical"
        initialValues={{ autoPay: false }}
        onFinish={onFinish}>
        <Form.Item name={'_id'} hidden>
          <Input type={'hidden'}></Input>
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="status" label="审核结果" rules={requiredInputRule}>
              <Radio.Group>
                <Radio value="approved">通过</Radio>
                <Radio value="rejected">拒绝</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {
            record &&
            auths.includes('transfer') &&
            record.payment.type === 'usdt' &&
            ['outflow', 'depositOutflow'].includes(record.type) &&
            <Col span={12}>
              <Form.Item name="autoPay" label="自动支付" rules={requiredInputRule}>
                <Radio.Group>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          }
        </Row>
        <Form.Item name="voucher" label="支付凭证">
          <Input maxLength={100} placeholder="请输入"></Input>
        </Form.Item>
        {/* <Form.Item name="adminRemarks" label="确认备注"> */}
        {/*   <Input.TextArea rows={3} maxLength={100} placeholder="请输入"/> */}
        {/* </Form.Item> */}
        <Form.Item name="adminRemarks" label="确认备注">
          <Select
            mode={'tags'}
            options={adminRemarks}
            allowClear
            placeholder="请选择"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
