import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取所有角色
export function all<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/all'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取角色列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取收费角色
export function getFeeRole<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  params = {
    sort: { sort: 'ascend' },
    ...params
  }
  const url = '/role/getFeeRole'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取收费角色
export function get<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/get'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 添加角色
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新角色
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除角色
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取角色和权限树
export function getMenusAndAuths<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/getMenusAndAuths'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 复制权限
export function copyAuth<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/role/copyAuth'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
