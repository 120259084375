import React, { memo, useEffect, useMemo, useState } from 'react'
import { Table, Tag, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { isMobile, RenderTelegramName } from '@/utils/hooks'
import { ListItem as Task } from './index'
import { ListItem as Channel } from '@/views/telegram/channel'
import { ListItem as Contact } from '@/views/telegram/contact'
import { usernameRegex } from '@/views/telegram/account/UpdateForm'
import { isObject } from '@/utils'
import { translate } from '@/utils/translate'
import { all as getChannel } from '@/views/telegram/channel/api'
import { all as getContact } from '@/views/telegram/contact/api'
import moment from 'dayjs'

export interface ListItem {
  key: string
  target?: Channel | Contact
  status: 'success' | 'error' | 'unknown'
  message: string
  targetId: string
}

interface Props {
  open: boolean
  task: Task
  dates?: Task['dates']
  result?: Task['result']
  targetList?: Task['params']['targetList']
  loopedCount?: number
  onCancel: () => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, task, dates = {}, result = {}, targetList = [], loopedCount, onCancel } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [targetInfoList, setTargetInfoList] = useState<Array<Channel | Contact>>([])

  const { _id, target } = task.params
  const resultList = useMemo(formatResultList, [dates, result, targetList, targetInfoList])

  const columns: ColumnsType<ListItem> = [
    {
      title: '#',
      width: 40,
      fixed: 'left',
      className: 'no-padding-right',
      render (v, r, index) {
        return index + 1
      }
    },
    {
      title: '任务目标',
      width: 180,
      ellipsis: { showTitle: false },
      dataIndex: 'target',
      render (value, record) {
        return value
          ? <RenderTelegramName value={value}/>
          : usernameRegex.regex.test(record.targetId) || /^\+[A-z0-9_]{16}$/.test(record.targetId)
            ? <a href={'https://t.me/' + record.targetId} target={'tgLink'}>{record.targetId}</a>
            : record.targetId
      }
    },
    {
      title: '任务结果',
      width: 100,
      dataIndex: 'status',
      filters: [
        {
          text: '成功',
          value: 'success'
        },
        {
          text: '失败',
          value: 'error'
        },
        {
          text: '未知',
          value: 'unknown'
        }
      ],
      filterMultiple: false,
      onFilter (value, record) {
        return value === record.status
      },
      render (value: ListItem['status']) {
        switch (value) {
          case 'success':
            return <Tag color={'success'}>成功</Tag>
          case 'unknown':
            return <Tag>未知</Tag>
          case 'error':
            return <Tag color={'error'}>失败</Tag>
        }
      }
    },
    {
      title: '失败原因',
      width: 220,
      dataIndex: 'message',
      render (value, record) {
        return record.status !== 'success' && translate(value)
      }
    },
    {
      title: '完成时间',
      width: 166,
      dataIndex: 'date',
      render (value) {
        return value && moment(value).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  ]
  const showLink = target === 'peerChannel' &&
    ['sendMessage', 'forwardMessages', 'sendMedia'].includes(task.action)
  if (showLink) {
    columns.splice(4, 0, {
      title: '消息链接',
      width: 80,
      dataIndex: 'message',
      render (value, record) {
        const link = record.target?.username
          ? `https://t.me/${record.target.username}/${value}`
          : `https://t.me/c/${record.targetId}/${value}`
        return record.status === 'success' &&
          <a href={link} target={'tgLink'}>查看</a>
      }
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getTargetInfoList, [_id, target])

  function getTargetInfoList () {
    if (!_id || !target) return
    const params = {
      account: _id
    }
    const apiFn = target === 'peerChannel' ? getChannel : getContact
    setLoading(true)
    apiFn(params).then(({ data, success }) => {
      success && setTargetInfoList(data)
    }).finally(() => setLoading(false))
  }

  function formatResultList (): ListItem[] {
    return Object.keys(result).map(key => {
      const targetItem = targetList[+key]
      const resultItem = result[key]
      const targetId = isObject(targetItem) ? targetItem.id : targetItem
      const target = targetInfoList.find(i => i.id === targetId)
      const status = resultItem === 'success' || /^\d+$/.test(resultItem)
        ? 'success'
        : resultItem === 'Telegram timeout' ? 'unknown' : 'error'

      return {
        key,
        date: dates[key],
        target,
        status,
        message: resultItem,
        targetId
      }
    })
  }

  return (
    <Modal
      title={loopedCount ? `第${loopedCount}轮` : '运行详情'}
      open={open}
      width={900}
      footer={null}
      centered={isMobile}
      onCancel={onCancel}>
      <Table
        rowKey="key"
        scroll={{ x: 800, y: 400 }}
        columns={columns}
        loading={loading}
        bordered
        dataSource={resultList}
        pagination={false}
      />
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
