import React, { useEffect, useRef, useState } from 'react'
import { InputNumber } from 'antd'

interface Props extends React.HTMLAttributes<HTMLElement> {
  value: string | number
  editable: boolean
  children: React.ReactNode
  handleSave: (value: string) => void
}

const EditableNumberCell: React.FC<Props> = props => {
  const { value, title, editable, children, handleSave, ...restProps } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [editing, setEditing] = useState(false)

  useEffect(editingOnchange, [editing])

  function editingOnchange () {
    if (editing) {
      setTimeout(() => inputRef.current!.select(), 100)
    }
  }

  function toggleEditing () {
    setEditing(!editing)
  }

  function save (e: any) {
    toggleEditing()
    handleSave(e.target.value)
  }

  let childNode = children

  if (editable) {
    childNode = editing
      ? <InputNumber
        ref={inputRef}
        min={1}
        max={999}
        size="middle"
        style={{ width: '100%' }}
        pattern="\d*"
        precision={0}
        defaultValue={value}
        placeholder={'1-999'}
        onBlur={save}
        onPressEnter={save}/>
      : <div style={{ paddingLeft: 11 }} className="common__editable-cell-content" onClick={toggleEditing}>
        {children}
      </div>
  }

  return (
    <td{...restProps}>
      {childNode}
    </td>
  )
}

export default EditableNumberCell
