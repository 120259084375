import React, { memo, useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { canInviteUsers, canSendMessage } from '@/utils'
import { getActiveId } from '@/store/slice/account'
import { setChannel } from '@/store/slice/channel'
import { ListItem } from '@/views/telegram/channel'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { usernameRegex } from '@/views/telegram/account/UpdateForm'
import * as api from '@/views/telegram/channel/api'

export interface SelectOption extends DefaultOptionType {
  label: React.ReactNode
  title?: string
  value: string
  access_hash?: string
}

interface Props extends Partial<SelectProps<string, SelectOption>> {
  open: boolean
  list?: ListItem[]
  filter?: 'invite' | 'sendMessage' | 'participant'
  autoSelectFirst?: true // 切换账号后是否自动选中第一个
}

const colorMap = {
  chat: {
    text: '群聊',
    color: '#52c41a'
  },
  broadcast: {
    text: '频道',
    color: '#faad14'
  },
  channelPublic: {
    text: '公开群',
    color: undefined
    // color: '#1677ff'
  },
  channelPrivate: {
    text: '私有群',
    color: '#52c41a'
  }
}

const Index: React.FC<Props> = (props) => {
  const { open, list: listProps, filter, autoSelectFirst, loading: loadingProps, onChange, ...restProps } = props
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const activeId = useSelector(getActiveId)
  const options: SelectOption[] = useMemo(() => {
    let _list
    switch (filter) {
      case 'invite':
        _list = list.filter(canInviteUsers)
        break
      case 'sendMessage':
        _list = list.filter(canSendMessage)
        break
      case 'participant':
        _list = list.filter(item => item._ !== 'chat' && (!item.broadcast || !!item.admin_rights))
        break
      default:
        _list = list
    }
    return _list.map(item => {
      const chatType = item._ === 'chat'
        ? 'chat'
        : item.broadcast ? 'broadcast' : usernameRegex.regex.test(item.username || '') ? 'channelPublic' : 'channelPrivate'
      return {
        label: <div>
          <span>{item.title}&nbsp;</span>
          <span style={{ color: colorMap[chatType].color, fontSize: 12, opacity: 0.5 }}>
            {colorMap[chatType].text}
          </span>
        </div>,
        title: item.title,
        value: item.id,
        access_hash: item.access_hash
      }
    })
  }, [list, filter])

  useEffect(getList, [open, activeId, listProps, dispatch])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectFirst, [options, autoSelectFirst])

  // 获取所有
  function getList () {
    if (listProps) return setList(listProps)
    if (!open || !activeId) return
    setLoading(true)
    const params = {
      account: activeId
    }
    api.all(params).then(({ data, success }) => {
      if (success) {
        setList(data)
        dispatch(setChannel(data))
      }
    }).finally(() => setLoading(false))
  }

  // 切换账号，群组变化后，选中第一个
  function selectFirst () {
    const first = options[0]
    if (autoSelectFirst && onChange) {
      onChange(first?.value, first)
    }
  }

  const filterOption: SelectProps<string | string[], SelectOption>['filterOption'] = (inputValue, option) => {
    return (option!.title!).toLowerCase().includes(inputValue.toLowerCase())
  }

  return (
    <Select
      {...restProps}
      options={options}
      loading={loadingProps || loading}
      showSearch
      maxTagCount={0}
      placeholder={'请选择群组'}
      dropdownMatchSelectWidth={280}
      filterOption={filterOption}
      onChange={onChange}/>
  )
}

export default memo(Index)
