import React, { ReactNode, useEffect, useState } from 'react'
import moment from 'dayjs'
import { Tag, Table, Button, Space, Popconfirm, Descriptions, Divider } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { PlusOutlined, TransactionOutlined, DollarOutlined } from '@ant-design/icons'
import { PaginationProps } from 'antd/es/pagination'
import { formatFilterValue, toThousandSplit } from '@/utils'
import {
  Status,
  FilterValueState,
  isMobile,
  paginationProps,
  SortOrderState,
  useAuthsSelector,
  useGetColumnSearchProps,
  useHasActions,
  createActions,
  statusColorAndIcon,
  useGetColumnRangePickerProps,
  useGetTableScrollX,
  useGetTableScrollY
} from '@/utils/hooks'
import { useParams } from 'react-router-dom'
import { typeLabel as PaymentLabel, ListItem as Payment } from '@/views/payments'
import CreateModal from './CreateModal'
import CheckModal from './CheckModal'
import TransferModal from '@/views/tron/TransferModal'
import * as api from './api'

const typeLabel = {
  inflow: '充值',
  outflow: '提现',
  renewal: '续费',
  payForAnother: '代付'
} as const
const statusLabel = {
  review: '待确认',
  approved: '已确认',
  rejected: '已取消'
}

interface BillPayment extends Payment {
  _id: string
  amount?: number
  receiptAccount?: string
}

interface ListItem {
  _id: string
  id: number // 账单id
  type: keyof typeof typeLabel // 账单类型
  amount: number // 账单金额
  status: Status // 账单状态
  role?: string // 续费角色
  user: string // 用户名
  agent?: string // 代理用户名
  receiveUser?: string
  creator: string // 创建人
  payment: BillPayment // 支付信息
  voucher?: string // 付款凭证
  remarks?: string // 账单备注信息
  adminRemarks?: string // 账单备注信息 管理员
  userBalance: number[] // 用户余额变动记录 [after,now]
  agentBalance: number[] // 营业额变动记录 [after,now]
  createdAt: string // 创建时间
  updatedAt: string // 更新时间
}

const actions = {
  update: undefined as ReactNode,
  remove: undefined as ReactNode
}

const errorCode: Record<string, string> = {
  N003011: '验证超时',
  N003012: '无交易记录',
  N003013: '支付凭证已过期',
  N003014: '支付凭证无效',
  N003015: '支付金额或支付地址不正确'
}

const Index: React.FC = () => {
  const { '*': paramId } = useParams<{ '*': string }>()
  const id = Number(paramId) ? [Number(paramId)] : null
  const [list, setList] = useState<ListItem[]>([])
  const [types, setTypes] = useState<Array<keyof typeof typeLabel>>([])
  const [record, setRecord] = useState<ListItem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({ id })
  const [sortOrder, setSortOrder] = useState<SortOrderState>({ createdAt: 'descend' })
  const [pagination, setPagination] = useState<PaginationProps>(paginationProps)
  const [open, setOpen] = useState<Record<string, boolean>>({})

  const auths = useAuthsSelector()
  const hasActions = useHasActions(Object.keys(actions))
  const filterUserProps = useGetColumnSearchProps('user')
  const filterAgentProps = useGetColumnSearchProps('agent')
  const userColumn: ColumnsType<ListItem> = auths.includes('userColumn')
    ? [
      {
        title: '用户',
        align: 'left',
        width: 120,
        dataIndex: 'user',
        className: 'no-padding-right',
        filteredValue: filtered.user || null,
        ...filterUserProps
      }
    ]
    : []
  const agentColumn: ColumnsType<ListItem> = auths.includes('agentColumn')
    ? [
      {
        title: '代理',
        align: 'left',
        width: 120,
        dataIndex: 'agent',
        className: 'no-padding-right',
        filteredValue: filtered.agent || null,
        ...filterAgentProps
      },
      {
        title: '余额',
        width: 100,
        dataIndex: 'agentBalance',
        render (value) {
          return !!value.length && <span>{value[0]} » {value[1]}</span>
        }
      }
    ]
    : []

  const { current, pageSize } = pagination
  const columns: ColumnsType<ListItem> = [
    {
      title: '状态',
      width: 100,
      fixed: 'left',
      filters: Object.keys(statusLabel).map((i) => {
        return {
          text: statusLabel[i as keyof typeof statusLabel],
          value: i
        }
      }),
      filteredValue: filtered.status || null,
      dataIndex: 'status',
      filterMultiple: false,
      render (value: ListItem['status']) {
        const map = statusColorAndIcon
        return <Tag icon={map[value].icon} color={map[value].color}>{statusLabel[value]}</Tag>
      }
    },
    {
      title: '账单ID',
      width: 80,
      dataIndex: 'id',
      filteredValue: filtered.id || null,
      ...useGetColumnSearchProps('id', 'number')
    },
    {
      title: '类型',
      width: 120,
      filters: types.map(i => ({ text: typeLabel[i] || i, value: i })),
      dataIndex: 'type',
      filteredValue: filtered.type || null,
      filterMultiple: false,
      render (value: ListItem['type']) {
        return typeLabel[value]
      }
    },
    {
      title: '账单金额',
      width: 100,
      dataIndex: 'amount',
      render (value, record) {
        switch (record.type) {
          case 'renewal':
          case 'outflow':
          case 'payForAnother':
            value = -value
            break
        }
        return `${toThousandSplit(value)}`
      }
    },
    {
      title: '支付方式',
      width: 120,
      dataIndex: 'payment',
      render (value: ListItem['payment']) {
        return value.name || PaymentLabel[value.type] || value.type
      }
    },
    {
      title: '支付金额',
      width: 100,
      dataIndex: 'payment',
      render (value: ListItem['payment'], record) {
        // const symbol: Record<ListItem['type'], string> = {
        //   tip: '',
        //   order: '',
        //   inflow: '',
        //   outflow: '-',
        //   renewal: '-',
        //   transfer: '-',
        //   depositInflow: '',
        //   depositOutflow: '-'
        // }
        // return `${symbol[record.type]}${toThousandSplit(value.amount)} ${value.currency}`
        return `${toThousandSplit(value.amount)} ${value.currency}`
      }
    },
    ...userColumn,
    {
      title: '余额',
      width: 100,
      dataIndex: 'userBalance',
      render (value) {
        return !!value.length && <span>{value[0]} » {value[1]}</span>
      }
    },
    ...agentColumn,
    {
      title: '提交时间',
      width: 164,
      sorter: true,
      sortOrder: sortOrder.createdAt,
      dataIndex: 'createdAt',
      filteredValue: filtered.createdAt || null,
      render (text) {
        return text && moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      ...useGetColumnRangePickerProps()
    },
    {
      title: '操作',
      width: 100,
      fixed: isMobile ? undefined : 'right',
      render (text, record) {
        actions.update = (
          record.status === 'review' &&
          <a onClick={() => updateStatus(record)}>
            审核
          </a>
        )
        actions.remove = (
          <Popconfirm
            title="你确定要删除这条记录吗？"
            placement="topRight"
            onConfirm={() => remove(record)}>
            <a>删除</a>
          </Popconfirm>
        )

        return createActions(actions, auths)
      }
    }
  ]
  !hasActions && columns.pop()
  const expandable: TableProps<ListItem>['expandable'] = {
    fixed: 'left',
    columnWidth: 32,
    rowExpandable: record => record.payment.type !== 'balance',
    expandedRowRender
  }

  const scrollX = useGetTableScrollX(columns, 32)
  const scrollY = useGetTableScrollY(list.length)

  useEffect(getList, [current, filtered, pageSize, sortOrder])
  useEffect(getType, [])

  function getList () {
    const params = {
      sort: sortOrder,
      page: current,
      pageSize,
      ...formatFilterValue(filtered)
    }
    setLoading(true)
    api.list(params).then(({ success, data }) => {
      if (success) {
        setList(data.rows)
        setPagination(state => ({
          ...state,
          total: data.total
        }))
      }
    }).finally(() => setLoading(false))
  }

  // 获取账单类型
  function getType () {
    api.getType().then(({ data, success }) => {
      success && setTypes(data)
    })
  }

  function create () {
    setRecord(undefined)
    setOpen({ create: true })
  }

  function updateStatus (record: ListItem) {
    setRecord(record)
    setOpen({ check: true })
  }

  function createOrUpdateSuccess (data?: ListItem) {
    if (data && record) {
      const { role, ...update } = data
      Object.assign(record, update)
      setList(JSON.parse(JSON.stringify(list)))
    } else {
      getList()
    }
  }

  function remove (record: ListItem) {
    const params = {
      _id: record._id
    }
    return api.remove(params).then(({ success }) => {
      success && getList()
    })
  }

  function resetFilter () {
    setFiltered({})
  }

  function expandedRowRender (record: ListItem) {
    return (
      <Descriptions
        size={'small'}
        column={1}
        bordered
        labelStyle={{ width: 100, textAlign: 'center' }}>
        <Descriptions.Item label="支付地址">
          {record.payment.receiptAccount || record.payment.account}
        </Descriptions.Item>
        {
          record.payment.receiptAccount &&
          auths.includes('update') &&
          <Descriptions.Item label="钱包地址">
            {record.payment.account}
          </Descriptions.Item>
        }
        {
          record.voucher &&
          <Descriptions.Item label="支付凭证">
            {record.voucher}
          </Descriptions.Item>
        }
        {
          record.remarks &&
          <Descriptions.Item label="提交备注">
            {record.remarks}
          </Descriptions.Item>
        }
        {
          record.adminRemarks &&
          <Descriptions.Item label="确认备注">
            {errorCode[record.adminRemarks] || record.adminRemarks}
          </Descriptions.Item>
        }
        {
          record.status !== 'review' &&
          record.updatedAt &&
          <Descriptions.Item label="确认时间">
            {moment(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </Descriptions.Item>
        }
      </Descriptions>
    )
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters, sorter) => {
    sorter = Array.isArray(sorter) ? sorter[0] : sorter
    const sort = {
      [sorter.field as string]: sorter.order
    }
    setSortOrder(sort)
    setFiltered(filters)
    setPagination(state => ({
      ...state,
      current: pagination.current,
      pageSize: pagination.pageSize
    }))
  }

  const footer: TableProps<ListItem>['footer'] = (data) => {
    let renewal = 0
    let inflow = 0
    let outflow = 0
    let agentIncome = 0
    let payForAnother = 0
    data.forEach(item => {
      const { type, amount, status, agentBalance } = item
      if (status !== 'approved') return
      switch (type) {
        case 'renewal':
          renewal += amount
          if (agentBalance.length) agentIncome += agentBalance[1] - agentBalance[0]
          break
        case 'inflow':
          inflow += amount
          break
        case 'outflow':
          outflow += amount
          break
        case 'payForAnother':
          payForAnother += amount
          break
      }
    })
    return <div>
      <Space wrap size={0}>
        <b>本页合计：</b>
        <span>续费：{toThousandSplit(renewal)}</span>
        <Divider type={'vertical'}/>
        {
          auths.includes('agentColumn') &&
          <>
            <span>代付：{toThousandSplit(payForAnother)}</span>
            <Divider type={'vertical'}/>
          </>
        }
        <span>充值：{toThousandSplit(inflow)}</span>
        <Divider type={'vertical'}/>
        <span>提现：{toThousandSplit(outflow)}</span>
        {
          auths.includes('agentColumn') &&
          <>
            <Divider type={'vertical'}/>
            <span>代理分成：{toThousandSplit(agentIncome)}</span>
          </>
        }
        {
          auths.includes('update') &&
          <>
            <Divider type={'vertical'}/>
            <span>平台收入：{toThousandSplit(renewal - agentIncome + payForAnother)}</span>
          </>
        }
      </Space>
    </div>
  }

  return (
    <div className="common">
      <Space className="common__header" wrap>
        <a href={'/pay/renewal'} target={'inflow'}>
          <Button type="primary" icon={<DollarOutlined/>}>
            续费
          </Button>
        </a>
        {
          auths.includes('create') &&
          <Button onClick={create} type="primary" icon={<PlusOutlined/>}>
            添加
          </Button>
        }
        {
          auths.includes('transfer') &&
          <Button onClick={() => setOpen({ transfer: true })} type="primary" icon={<TransactionOutlined/>}>
            转账
          </Button>
        }
        <Button onClick={resetFilter}>
          重置筛选
        </Button>
      </Space>
      <Table
        rowKey="_id"
        scroll={{ x: scrollX, y: scrollY }}
        footer={footer}
        loading={loading}
        columns={columns}
        dataSource={list}
        expandable={expandable}
        pagination={pagination}
        onChange={onChange}/>
      <CheckModal
        open={open.check}
        record={record}
        errorCode={errorCode}
        onSuccess={createOrUpdateSuccess}
        onCancel={() => setOpen({})}/>
      <CreateModal
        open={open.create}
        types={types}
        onSuccess={getList}
        onCancel={() => setOpen({})}/>
      <TransferModal
        open={open.transfer}
        onCancel={() => setOpen({})}/>
    </div>
  )
}

export { typeLabel }
export type { ListItem }
export default Index
