import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取菜单
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 添加
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新菜单
export function update<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新菜单状态
export function updateEnable<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/updateEnable'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除菜单
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/menu/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
