import React, { memo, useMemo, useState } from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { useSelector } from 'react-redux'
import { getActiveId } from '@/store/slice/account'
import { ListItem } from './index'
import * as tg from '@/views/telegram/api'

interface Props {
  open: boolean
  list: ListItem[]
  selectedRowKeys: string[]
  onCancel: () => void
}

interface FormValues {
  title: string
}

const Index: React.FC<Props> = (props) => {
  const { open = false, list, selectedRowKeys, onCancel } = props
  const [loading, setLoading] = useState<boolean>(false)

  const activeId = useSelector(getActiveId)
  const filteredChats = useMemo(() => {
    const selectedChats = selectedRowKeys.length ? list.filter(i => selectedRowKeys.includes(i.id)) : list
    return selectedChats.filter(i => i.username)
  }, [list, selectedRowKeys])

  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  function onFinish (values: FormValues) {
    const params = {
      _id: activeId,
      title: values.title,
      peers: filteredChats.map(i => ({
        id: i.id,
        access_hash: i.access_hash
      }))
    }
    setLoading(true)
    tg.exportInvite(params).then(({ success, data }) => {
      if (success) {
        onCancel()
        modal.success({
          title: '邀请链接',
          content: <div>
            <a rel={'noreferrer'} href={data} target={'tglink'}>{data}</a>
          </div>,
          okText: '已保存'
        })
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title={'分享群组'}
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={onCancel}>
      <Form
        name="share"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Form.Item label="群组数量"
          extra={'频道和群组必须是公开的，不满足条件的频道或群组已被过滤，单次分享数量限制：普通号 - 100，会员号 - 200'}>
          <InputNumber value={filteredChats.length} disabled/>
        </Form.Item>
        <Form.Item name="title" label="文件夹标题" rules={requiredInputRule}>
          <Input maxLength={12} placeholder={'请输入'}/>
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
