import React, { memo, useState } from 'react'
import { Modal, Form, Alert, message } from 'antd'
import { isMobile, requiredSelectRule } from '@/utils/hooks'
import { AccessItem } from '@/views/telegram/common'
import { useSelector } from 'react-redux'
import { getActiveId } from '@/store/slice/account'
import FormChannelSelect from '@/views/telegram/FormChannelSelect'
import * as tg from '@/views/telegram/api'

interface Props {
  open: boolean
  targetList: AccessItem[]
  onCancel: () => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, targetList, onCancel } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [channel, setChannel] = useState<AccessItem>()

  const activeId = useSelector(getActiveId)

  const [form] = Form.useForm()

  function onChange (value: string, option: any) {
    setChannel({
      id: value,
      access_hash: option.access_hash
    })
  }

  function onFinish () {
    const params = {
      _id: activeId,
      channel,
      targetList
    }
    setLoading(true)

    tg.inviteToChannel<number>(params).then(({ data, success }) => {
      if (success) {
        onCancel()
        message.success(`成功邀请 ${data} 人`)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title={`邀请入群 - ${targetList.length}个目标`}
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={onCancel}>
      <Form
        name="invite"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Form.Item>
          <Alert type={'info'} message={'每个号每天大约能邀请50个用户，具体结果可能会有所差异'}/>
        </Form.Item>
        <Form.Item
          name="channel"
          label="群组"
          rules={requiredSelectRule}
          tooltip="已去除无邀请权限的群组/频道"
        >
          <FormChannelSelect open={open} filter={'invite'} onChange={onChange}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
