import React, { useState, useEffect, memo } from 'react'
import { Modal, Form, Input, message, Upload, UploadFile } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { ListItem } from './index'
import { Rule } from 'antd/es/form'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { UploadProps } from 'antd/lib/upload/interface'
import { AxiosRequestConfig } from 'axios'
import { transformArrayBufferToBase64Image, validaImageFile } from '@/utils'
import { RcFile } from 'antd/es/upload'
import ImagePreview from '@/components/ImagePreview'
import * as tg from '../api'
import scss from './index.module.scss'
import { translate } from '@/utils/translate'

interface Props {
  open: boolean
  record: ListItem
  selectedRowKeys: string[]
  onSuccess: (data?: ListItem) => void
  onCancel: () => void
}

export const usernameRegex = {
  regex: /^[a-zA-Z][a-zA-Z0-9_]{4,32}$/,
  message: '用户名为5-32位字母开头的数字、下划线组合'
}

const Index: React.FC<Props> = (props) => {
  const { open = false, record, selectedRowKeys, onCancel, onSuccess } = props
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewUrl, setPreviewUrl] = useState<string>()
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>('')

  const [form] = Form.useForm()
  const usernameRule: Rule[] = [
    {
      validator (rule, value) {
        if (!value || (!selectedRowKeys.length && value === record.user.username)) {
          setValidateStatus('')
          return Promise.resolve()
        } else if (usernameRegex.regex.test(value)) {
          setValidateStatus('validating')
          return checkUsername(value)
        } else {
          setValidateStatus('error')
          return Promise.reject(usernameRegex.message)
        }
      },
      validateTrigger: 'onBlur'
    }
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(toggleVisible, [open, record, selectedRowKeys])

  function toggleVisible () {
    if (open && record && !selectedRowKeys.length) {
      form.setFieldsValue({
        about: record.about,
        username: record.user.username,
        first_name: record.user.first_name,
        last_name: record.user.last_name
      })
      getProfilePhoto(record.user.photo)
    } else {
      setFileList([])
      setValidateStatus('')
    }
  }

  function checkUsername (value: string) {
    const params = {
      _id: record._id,
      username: value,
      _isShowErrorTips: false
    }
    return tg.checkUsername(params).then(({ success, data, message }) => {
      if (success) {
        if (data) {
          setValidateStatus('success')
        } else {
          setValidateStatus('error')
          return Promise.reject('用户名已存在')
        }
      } else {
        setValidateStatus('error')
        return Promise.reject(translate(message))
      }
    })
  }

  // 获取头像
  function getProfilePhoto (photo: ListItem['user']['photo']) {
    if (!photo?.photo_id) return
    const params = {
      _id: record._id,
      id: record.user.id,
      big: false,
      access_hash: record.user.access_hash,
      photo_id: photo.photo_id
    }
    return tg.getProfilePhoto(params).then(data => {
      setFileList([{
        name: 'photo.jpeg',
        uid: record._id,
        url: transformArrayBufferToBase64Image(data),
        status: 'done'
      }])
    })
  }

  // 图片预览
  function onPreview (file: UploadFile) {
    if (file.status === 'done') {
      setPreviewUrl(file.url)
    } else {
      const url = URL.createObjectURL(file.originFileObj as Blob)
      setPreviewUrl(url)
    }
  }

  // 上传头像
  const customRequest: UploadProps['customRequest'] = (options) => {
    validaImageFile(options.file as RcFile).then(() => {
      const params = {
        _id: selectedRowKeys.length ? selectedRowKeys : record._id,
        file: options.file
      }
      const config: AxiosRequestConfig = {
        onUploadProgress: options.onProgress
      }
      const apiFn = selectedRowKeys.length ? tg.batchUploadProfilePhoto : tg.uploadProfilePhoto

      // @ts-ignore
      return apiFn<ListItem>(params, config).then(({ success, data, message: msg }) => {
        if (success) {
          onSuccess(data)
          options.onSuccess!(null)
          message.success('头像更新成功')
        } else {
          return Promise.reject(new Error(msg))
        }
      })
    }).catch(options.onError)
  }

  function onFinish (values: Record<string, any>) {
    let apiFn
    const params: Record<string, any> = {
      about: values.about && encodeURIComponent(values.about),
      username: values.username,
      first_name: encodeURIComponent(values.first_name),
      last_name: values.last_name && encodeURIComponent(values.last_name)
    }
    setLoading(true)

    if (selectedRowKeys.length) {
      params._id = selectedRowKeys
      selectedRowKeys.forEach((id, index) => (params[id] = index + 1))
      apiFn = tg.batchUpdateUsernameAndProfile(params as any)
    } else {
      params._id = record._id
      apiFn = values.username === record.user.username
        ? tg.updateProfile(params)
        : tg.updateUsernameAndProfile(params)
    }

    apiFn.then(({ success, data, message: msg }) => {
      if (success) {
        onCancel()
        onSuccess(data)
        data && message.success(msg)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title={'编辑资料'}
      className="common-modal"
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={() => form.submit()}
      onCancel={onCancel}>
      <Form
        name="update"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Form.Item>
          <Upload
            accept="image/png,image/jpeg"
            listType="picture-circle"
            className={scss.upload}
            fileList={fileList}
            maxCount={1}
            onPreview={onPreview}
            onChange={info => setFileList(info.fileList)}
            customRequest={customRequest}>
            {
              fileList.length
                ? null
                : <div>
                  <PlusOutlined/>
                  <div style={{ marginTop: 8 }}>选择图片</div>
                </div>
            }
          </Upload>
        </Form.Item>
        <Form.Item
          name="username"
          label="用户名"
          rules={usernameRule}
          tooltip={'批量编辑时，会在您设置的用户名后添加编号后缀，例如：username_1、username_2'}
          hasFeedback
          validateStatus={validateStatus}>
          <Input allowClear placeholder="用户名为空，则会删除用户名"/>
        </Form.Item>
        <Form.Item
          name="first_name"
          label="姓氏"
          rules={requiredInputRule}
          tooltip={'批量编辑时，会在您设置的姓氏后添加编号后缀，例如：群发助手1、群发助手2'}>
          <Input showCount maxLength={64} placeholder="请输入"/>
        </Form.Item>
        <Form.Item name="last_name" label="名字">
          <Input allowClear showCount maxLength={64} placeholder="请输入"/>
        </Form.Item>
        <Form.Item name="about" label="个人简介">
          <Input.TextArea
            rows={3}
            showCount
            maxLength={70}
            allowClear
            placeholder="请输入"
            // onPressEnter={form.submit}
          />
        </Form.Item>
      </Form>
      <ImagePreview url={previewUrl} setUrl={setPreviewUrl}/>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
