import React, { useEffect, useState } from 'react'
import { Table, Descriptions, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useGetTableScrollX } from '@/utils/hooks'
import { ListItem } from '@/views/roles'
import { all } from '@/views/roles/api'
// import domain from '@/config/domain'

const Index: React.FC = () => {
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const Check = <CheckCircleOutlined style={{ color: '#52c41a' }}/>
  const Close = <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>

  const columns: ColumnsType<ListItem> = [
    {
      title: '会员类型',
      width: 150,
      dataIndex: 'name'
    },
    {
      title: '包月费用',
      width: 100,
      dataIndex: 'amount',
      render (value) {
        return `$ ${value}`
      }
    },
    {
      title: '账号数量',
      width: 100,
      dataIndex: 'account'
    },
    {
      title: '群发消息',
      width: 100,
      render: () => Check
    },
    {
      title: '批量加群',
      width: 100,
      render: () => Check
    },
    {
      title: '克隆群组',
      width: 100,
      render: () => Check
    },
    {
      title: '批量私聊',
      width: 100,
      render: () => Check
    },
    {
      title: '查找附近',
      width: 100,
      render: () => Check
    },
    {
      title: '指定群拉人',
      width: 100,
      render: () => Check
    },
    {
      title: '自动回复',
      width: 100,
      dataIndex: 'type',
      render: () => Check
    },
    {
      title: '转发消息',
      width: 100,
      dataIndex: 'type',
      render: value => value === 'user' ? Close : Check
    },
    {
      title: '用户采集',
      width: 100,
      dataIndex: 'type',
      render: value => value === 'user' ? Close : Check
    },
    {
      title: <div>
        <Tooltip title={'试用会员使用系统发出的消息，尾部添加将本群发系统广告'}>
          <span>无广告</span>&nbsp;<QuestionCircleOutlined/>
        </Tooltip>
      </div>,
      width: 100,
      dataIndex: 'type',
      render: value => value === 'user' ? Close : Check
    },
    {
      title: <div>
        <Tooltip title={'单人使用一台独立服务器，避免使用共用服务器时互相影响，引起官方风控，如需独立服务器，请联系客服开通'}>
          <span>独立服务器</span>&nbsp;<QuestionCircleOutlined/>
        </Tooltip>
      </div>,
      width: 120,
      dataIndex: 'amount',
      render: value => value < 300 ? Close : Check
    }
  ]
  const scrollX = useGetTableScrollX(columns)

  useEffect(getList, [])

  function getList () {
    const params = {
      sort: { sort: 'ascend' },
      type: ['vip', 'user'],
      status: true
    }
    setLoading(true)
    all(params).then(({ success, data }) => {
      if (success) {
        setList(data)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <div className="common">
      <Table
        rowKey="_id"
        scroll={{ x: scrollX }}
        loading={loading}
        columns={columns}
        bordered
        dataSource={list}
        pagination={false}/>
      {/* <h4 style={{ margin: '1em 8px' }}>其他说明</h4> */}
      {/* <Descriptions */}
      {/*   size={'small'} */}
      {/*   column={1} */}
      {/*   bordered */}
      {/*   labelStyle={{ width: 110 }}> */}
      {/*   <Descriptions.Item label="无广告">试用会员使用系统发出的消息，尾部添加将本群发系统广告</Descriptions.Item> */}
      {/*   <Descriptions.Item */}
      {/*     label="独立服务器">单人使用一台独立服务器，避免使用共用服务器时互相影响，引起官方风控，如需独立服务器，请联系客服开通</Descriptions.Item> */}
      {/*   /!* { *!/ */}
      {/*   /!*   !domain.agent && *!/ */}
      {/*   /!*   <Descriptions.Item label="招收代理">提供高额分成，独立代理网，有量有资源的欢迎来谈</Descriptions.Item> *!/ */}
      {/*   /!* } *!/ */}
      {/* </Descriptions> */}
    </div>
  )
}

export default Index
