import React, { Key, memo, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Space,
  Badge,
  Table,
  Popconfirm,
  Tag,
  Divider,
  Dropdown,
  MenuProps,
  Upload,
  message,
  Tooltip
} from 'antd'
import { PaginationProps } from 'antd/es/pagination'
import { ColumnsType, TableProps } from 'antd/es/table'
import {
  DeleteOutlined,
  MessageOutlined,
  CommentOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined, ReloadOutlined
} from '@ant-design/icons'
import { formatFilterValue } from '@/utils'
import {
  FilterValueState,
  isMobile,
  paginationProps,
  RenderTelegramName, SortOrderState,
  useGetColumnSearchProps,
  useGetTableScrollX,
  useGetTableScrollY
} from '@/utils/hooks'
import { getActiveAccount, getActiveId } from '@/store/slice/account'
import { useSelector } from 'react-redux'
import { getStatusSort, getStatusText } from '@/utils/depend'
import { RcFile } from 'antd/es/upload'
import { User } from '@/views/telegram/types'
import { TableRowSelection } from 'antd/lib/table/interface'
import { AccessItem } from '@/views/telegram/common'
import { SELECTION_ALL, SELECTION_NONE } from 'antd/lib/table/hooks/useSelection'
import { utils, read, writeFile } from 'xlsx'
import SendMessage from '@/views/telegram/SendMessage'
import AddModal from './AddModal'
import Invite from './Invite'
import * as tg from '../api'
import * as api from './api'
import * as taskApi from '@/views/task/api'

export interface ListItem extends User {
  sort: number
}

interface SelectTableProps {
  recent?: boolean
  onChange: (data?: AccessItem) => void
}

const importKeyMap = {
  first_name: '名字',
  last_name: '姓氏',
  phone: '手机号',
  username: '用户名'
}

const Index: React.FC = () => {
  const [all, setAll] = useState<ListItem[]>([])
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({})
  const [sortOrder, setSortOrder] = useState<SortOrderState>({ status: 'descend' })
  const [pagination, setPagination] = useState<PaginationProps>(paginationProps)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [open, setOpen] = useState<Record<string, boolean>>({})

  const account = useSelector(getActiveAccount)
  const activeId = useSelector(getActiveId)
  const targetList = useMemo(() => {
    return all.filter(i => !i.deleted && selectedRowKeys.includes(i.id))
      .map(i => ({ id: i.id, access_hash: i.access_hash }))
  }, [all, selectedRowKeys])

  const { current, pageSize } = pagination
  const items: MenuProps['items'] = [
    {
      key: 'invite',
      icon: <CommentOutlined/>,
      label: '邀请入群',
      onClick: invite
    },
    {
      key: 'reload',
      icon: <ReloadOutlined/>,
      label: '同步好友',
      disabled: loading,
      onClick: reload
    },
    {
      key: 'removes',
      icon: <DeleteOutlined/>,
      label: <Popconfirm
        title="你确定要删除选中记录吗？"
        onConfirm={() => removes()}>
        删除
      </Popconfirm>,
      danger: true
    },
    {
      key: 'exportExcel',
      icon: <ExportOutlined/>,
      label: '导出',
      onClick: exportExcel
    },
    {
      key: 'importContact',
      icon: <ImportOutlined/>,
      label: (
        <Upload
          accept=".xlsx"
          maxCount={1}
          showUploadList={false}
          beforeUpload={beforeUpload}>
          导入
        </Upload>
      )
    }
  ]
  const columns: ColumnsType<ListItem> = [
    {
      title: '用户名称',
      width: 200,
      dataIndex: 'first_name',
      filteredValue: filtered.first_name || null,
      render (value, record) {
        return <RenderTelegramName value={record}/>
      },
      ...useGetColumnSearchProps('first_name', 'text')
    },
    {
      title: '手机号',
      width: 140,
      dataIndex: 'phone',
      filteredValue: filtered.phone || null,
      ...useGetColumnSearchProps('phone', 'text')
    },
    {
      title: '用户名',
      width: 160,
      dataIndex: 'username',
      filteredValue: filtered.username || null,
      ...useGetColumnSearchProps('username', 'text'),
      onFilter (value, record) {
        return String(record.username).toLowerCase().includes(String(value).toLowerCase())
      }
    },
    {
      title: '双向好友',
      width: 100,
      dataIndex: 'mutual_contact',
      filters: [
        {
          text: '是',
          value: true
        },
        {
          text: '否',
          value: false
        }
      ],
      filteredValue: filtered.mutual_contact || null,
      filterMultiple: false,
      render (text) {
        return text
          ? <Tag color="success">是</Tag>
          : <Tag color="error">否</Tag>
      }
    },
    {
      title: '账号状态',
      width: 100,
      dataIndex: 'deleted',
      filters: [
        {
          text: '在用',
          value: false
        },
        {
          text: '注销',
          value: true
        }
      ],
      filteredValue: filtered.deleted || null,
      filterMultiple: false,
      render (text) {
        return text
          ? <Tag color="error">注销</Tag>
          : <Tag color="success">在用</Tag>
      }
    },
    {
      title: '活跃状态',
      dataIndex: 'status',
      width: 140,
      filters: [
        {
          text: '在线',
          value: 'userStatusOnline'
        },
        {
          text: '上线时间',
          value: 'userStatusOffline'
        },
        {
          text: '近期上线',
          value: 'userStatusRecently'
        },
        {
          text: '近期一周上线',
          value: 'userStatusLastWeek'
        },
        {
          text: '近期一月上线',
          value: 'userStatusLastMonth'
        },
        {
          text: '很久没上线',
          value: ''
        }
      ],
      filteredValue: filtered.status || null,
      sorter: true,
      sortOrder: sortOrder.status,
      render (text, record) {
        return getStatusText(record.status)
      }
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      fixed: 'right',
      render (text, record) {
        return (
          <>
            <a onClick={() => sendMessage(record)}>
              发送消息
            </a>
            <Divider type={'vertical'}/>
            <Popconfirm
              title="你确定要删除这条记录吗？"
              placement="topRight"
              onConfirm={() => removes([record.id])}>
              <a>删除</a>
            </Popconfirm>
          </>
        )
      }
    }
  ]
  const rowSelection: TableRowSelection<ListItem> = {
    fixed: true,
    selections: [SELECTION_ALL, SELECTION_NONE],
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange (keys) {
      setSelectedRowKeys(keys)
    }
  }

  const scrollX = useGetTableScrollX(columns, 32)
  const scrollY = useGetTableScrollY(list.length)

  useEffect(getList, [activeId])
  useEffect(getPageList, [all, current, pageSize, filtered, sortOrder])

  // 获取所有
  function getList (getAll?: boolean) {
    if (!activeId) return
    const params = {
      [getAll ? '_id' : 'account']: activeId
    }
    const apiFn = getAll ? tg.getContacts : api.all
    setLoading(true)
    apiFn<ListItem[]>(params).then(({ data, success }) => {
      if (success) {
        data.forEach(item => (item.sort = getStatusSort(item.status)))
        setAll(data)
        setSelectedRowKeys([])
        setPagination(state => ({
          ...state,
          current: 1
        }))
      }
    }).finally(() => setLoading(false))
  }

  // 获取分页数据
  function getPageList () {
    let list: ListItem[] = all.slice(0)
    const page = current!
    const skip = (page - 1) * pageSize!
    const limit = page * pageSize!
    const filters = formatFilterValue(filtered)
    const filterKeys = Object.keys(filters)

    if (sortOrder.status) {
      list = list.sort((a, b) => {
        if (sortOrder.status === 'descend') {
          return b.sort - a.sort
        }
        return a.sort - b.sort
      })
    }

    if (filterKeys.length) {
      list = list.filter(item => {
        // 每一条数据都需要满足所有筛选条件才返回
        const checks: boolean[] = []

        filterKeys.forEach(key => {
          const filterValue: any = filters[key]
          switch (key) {
            case 'phone':
              checks.push(String(item.phone).includes(filterValue))
              break
            case 'status': {
              const status = item.status?._ || ''
              checks.push(filterValue.includes(status))
              break
            }
            case 'deleted':
              checks.push(item.deleted === filterValue)
              break
            case 'username':
              checks.push(String(item.username).includes(filterValue))
              break
            case 'first_name': {
              const fullName = item.first_name + (item.last_name || '')
              checks.push(fullName.toLowerCase().includes(filterValue.toLowerCase()))
              break
            }
            case 'mutual_contact':
              checks.push(item.mutual_contact === filterValue)
              break
          }
        })

        return checks.every(item => item)
      })
    }

    setList(list.slice(skip, limit))
    setPagination(state => ({
      ...state,
      total: list.length
    }))
  }

  // 邀请入群
  function invite () {
    if (!targetList.length) return
    setOpen({ invite: true })
  }

  // 同步官方好友列表
  function reload () {
    getList(true)
  }

  // 导出列表
  function exportExcel () {
    const sheetTitle = Object.values(importKeyMap)
    const sheetData = all.map(item => ({
      first_name: item.first_name,
      last_name: item.last_name,
      phone: item.phone,
      username: item.username
    }))
    const workbook = utils.book_new()
    const worksheet = utils.json_to_sheet(sheetData)
    const maxWidth = sheetData.reduce((width, item) => Math.max(width, item.first_name.length * 1.8), 30)
    worksheet['!cols'] = [
      { wch: maxWidth },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ]
    utils.sheet_add_aoa(worksheet, [sheetTitle], { origin: 'A1' })
    utils.book_append_sheet(workbook, worksheet, '好友列表')
    writeFile(workbook, `好友列表_${account!.user.phone}.xlsx`, { compression: true })
  }

  // 导入列表
  function importContact (list: typeof importKeyMap[]) {
    if (!activeId) return
    const allPhone = all.map(i => i.phone).filter(Boolean)
    const allUsername = all.map(i => i.username).filter(Boolean)
    list = list.filter(item => !allPhone.includes(item.phone) && !allUsername.includes(item.username))
    const phones = list.filter(item => item.phone)
    const usernames = list.filter(item => !item.phone && item.username).map(i => i.username)

    setLoading(true)

    Promise.all([
      task(),
      importContacts()
    ]).then(([task, phones]) => {
      if (task || phones) {
        message.success(`手机号导入 ${phones} 个` + (task ? '，用户名添加好友任务已创建' : ''))
        return getList()
      }
    }).finally(() => setLoading(false))

    function importContacts () {
      if (!phones.length) return null
      const params = {
        _id: [activeId!],
        targetList: phones
      }
      return tg.importContacts<string[]>(params).then(({ success, data }) => {
        if (success) {
          return data[0].length
        }
      })
    }

    function task () {
      if (!usernames.length) return null
      const task = {
        action: 'addContactsByUsername',
        params: {
          _id: activeId,
          delay: 5,
          delayUnit: 'seconds',
          targetList: usernames
        }
      }
      return taskApi.create(task)
    }
  }

  function removes (id?: Key[]) {
    if (!id && !selectedRowKeys.length) return
    const params = {
      _id: activeId,
      users: id || selectedRowKeys
    }
    return tg.deleteContacts(params).then(({ success }) => {
      if (success) {
        getList()
      }
    })
  }

  function sendMessage (record?: ListItem) {
    if (record) setSelectedRowKeys([record.id])
    setOpen({ sendMessage: true })
  }

  function beforeUpload (file: RcFile) {
    const check = /\.xlsx$/.test(file.name)
    if (check) {
      const reader = new FileReader()
      reader.onload = function () {
        try {
          const workbook = read(reader.result, { type: 'binary' })
          const sheetNames = workbook.SheetNames
          const worksheet = workbook.Sheets[sheetNames[0]]
          const sheetTitle = Object.keys(importKeyMap)
          utils.sheet_add_aoa(worksheet, [sheetTitle], { origin: 'A1' })
          const json = utils.sheet_to_json(worksheet)
          importContact(json as any)
        } catch (err) {
          message.error('文件解析失败')
        }
      }
      reader.readAsBinaryString(file)
    } else {
      message.error('请上传平台导出的Excel文件')
    }

    return false
  }

  function resetFilter () {
    getList()
    setFiltered({})
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters, sorter) => {
    sorter = Array.isArray(sorter) ? sorter[0] : sorter
    const sort = {
      [String(sorter.field)]: sorter.order
    }
    setFiltered(filters)
    setSortOrder(sort)
    setPagination(state => ({
      ...state,
      ...pagination
    }))
  }

  return (
    <div className="common">
      <Space className="common__header" wrap>
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => setOpen({ add: true })}>
          添加好友
        </Button>
        {
          isMobile
            ? <Badge count={targetList.length} size={'small'}>
              <Dropdown.Button
                type={'primary'}
                menu={{ items }}
                onClick={() => sendMessage()}>
                <MessageOutlined/>
                <span>发送消息</span>
              </Dropdown.Button>
            </Badge>
            : <>
              <Badge count={targetList.length} size={'small'} overflowCount={999}>
                <Button type="primary" icon={<MessageOutlined/>} onClick={() => sendMessage()}>
                  发送消息
                </Button>
              </Badge>
              <Badge count={targetList.length} size={'small'} overflowCount={999}>
                <Button type="primary" icon={<CommentOutlined/>} onClick={invite}>
                  邀请入群
                </Button>
              </Badge>
              <Tooltip title={'从TG官方同步账号好友列表'}>
                <Button icon={<ReloadOutlined/>} disabled={loading} onClick={reload}>
                  同步好友
                </Button>
              </Tooltip>
              <Popconfirm
                title="你确定要删除选中记录吗？"
                onConfirm={() => removes()}>
                <Badge count={selectedRowKeys.length} size={'small'} overflowCount={999}>
                  <Button icon={<DeleteOutlined/>} type="dashed" danger>
                    删除
                  </Button>
                </Badge>
              </Popconfirm>
              <Button icon={<ExportOutlined/>} onClick={exportExcel}>
                导出
              </Button>
              <Upload
                accept=".xlsx"
                maxCount={1}
                showUploadList={false}
                beforeUpload={beforeUpload}>
                <Button icon={<ImportOutlined/>}>
                  导入
                </Button>
              </Upload>
            </>
        }
        <Button onClick={resetFilter} disabled={loading}>
          重置筛选
        </Button>
      </Space>
      <Table
        rowKey="id"
        scroll={{ x: scrollX, y: scrollY }}
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={pagination}
        rowSelection={rowSelection}
        onChange={onChange}/>
      <AddModal
        open={open.add}
        onSuccess={getList}
        onCancel={() => setOpen({})}
      />
      <Invite
        open={open.invite}
        targetList={targetList}
        onCancel={() => setOpen({})}
      />
      <SendMessage
        open={open.sendMessage}
        target={'peerUser'}
        targetList={targetList}
        canChangeTargetList
        onClose={() => setOpen({})}/>
    </div>
  )
}

const _ContactSelectTable: React.FC<SelectTableProps> = (props) => {
  const { recent, onChange } = props
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const activeId = useSelector(getActiveId)

  const columns: ColumnsType<ListItem> = [
    {
      title: '用户名称',
      dataIndex: 'first_name',
      render (value, record) {
        return <RenderTelegramName value={record}/>
      },
      ...useGetColumnSearchProps('first_name', 'text', true)
    }
  ]
  const rowSelection: TableRowSelection<ListItem> = {
    type: 'radio',
    selectedRowKeys,
    onChange: setSelectedRowKeys
  }
  const scrollY = useGetTableScrollY(list.length, 0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getListFn, [activeId, recent])
  useEffect(selectFirst, [list])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectOnchange, [list, selectedRowKeys])

  function getListFn () {
    if (!activeId) return
    if (recent) {
      getRecentList()
    } else {
      getList()
    }
  }

  // 获取所有
  function getList () {
    setLoading(true)
    const params = {
      account: activeId
    }
    api.all<ListItem[]>(params).then(({ data, success }) => {
      if (success) {
        data.forEach(item => (item.sort = getStatusSort(item.status)))
        data.sort((a, b) => b.sort - a.sort)
        setList(data)
      }
    }).finally(() => setLoading(false))
  }

  // 获取最近
  function getRecentList () {
    const params = {
      _id: activeId
    }
    setLoading(true)
    tg.getDialogs(params)
      .then(({ data, success }) => {
        if (success) {
          setList(data.users)
        }
      })
      .finally(() => setLoading(false))
  }

  // 默认选中第一个
  function selectFirst () {
    const first = list[0]
    setSelectedRowKeys(first ? [first.id] : [])
  }

  function selectOnchange () {
    const item = list.find(i => i.id === selectedRowKeys[0])
    onChange(item && {
      id: item.id,
      access_hash: item.access_hash
    })
  }

  const onRow: TableProps<ListItem>['onRow'] = (record) => {
    return {
      onClick () {
        setSelectedRowKeys([record.id])
      }
    }
  }

  return (
    <Table
      rowKey="id"
      scroll={{ x: '100%', y: scrollY }}
      loading={loading}
      columns={columns}
      dataSource={list}
      pagination={false}
      rowClassName={'cursor'}
      rowSelection={rowSelection}
      onRow={onRow}
    />
  )
}

const ContactSelectTable = memo(_ContactSelectTable)

export { ContactSelectTable }
export default Index
