import React, { memo, useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveId } from '@/store/slice/account'
import { setContact } from '@/store/slice/contact'
import { SelectOption } from '@/views/telegram/FormChannelSelect'
import { ListItem } from '@/views/telegram/contact'
import * as tg from '@/views/telegram/api'
import * as api from '@/views/telegram/contact/api'

interface Props extends Partial<SelectProps<string, SelectOption>> {
  open: boolean
  list?: ListItem[]
  recent?: boolean
  filter?: 'sendMessage'
  autoSelectFirst?: true // 切换账号后是否自动选中第一个
}

const Index: React.FC<Props> = (props) => {
  const {
    open,
    list: listProps,
    recent,
    filter,
    autoSelectFirst,
    loading: loadingProps,
    onChange,
    ...restProps
  } = props
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const activeId = useSelector(getActiveId)
  const options: SelectOption[] = useMemo(() => {
    let _list
    switch (filter) {
      case 'sendMessage':
        _list = list.filter(i => !i.deleted)
        break
      default:
        _list = list
    }
    return _list.map(item => ({
      label: [item.first_name, item.last_name].filter(Boolean).join(' ') || '账号已注销',
      value: item.id,
      access_hash: item.access_hash
    }))
  }, [list, filter])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getListFn, [open, recent, activeId, listProps])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectFirst, [options, autoSelectFirst])

  function getListFn () {
    if (listProps) return setList(listProps)
    if (!open || !activeId) return
    if (recent) {
      getRecentList()
    } else {
      getList()
    }
  }

  // 获取所有
  function getList () {
    setLoading(true)
    const params = {
      account: activeId
    }
    api.all(params).then(({ data, success }) => {
      if (success) {
        dispatch(setContact(data))
        setList(data)
      }
    }).finally(() => setLoading(false))
  }

  // 获取最近
  function getRecentList () {
    const params = {
      _id: activeId
    }
    setLoading(true)
    tg.getDialogs(params)
      .then(({ data, success }) => {
        if (success) {
          setList(data.users)
        }
      })
      .finally(() => setLoading(false))
  }

  // 切换账号，群组变化后，选中第一个
  function selectFirst () {
    const first = options[0]
    if (autoSelectFirst && onChange) {
      onChange(first?.value, first)
    }
  }

  const filterOption: SelectProps<string, SelectOption>['filterOption'] = (inputValue, option) => {
    return (option!.label! as string).toLowerCase().includes(inputValue.toLowerCase())
  }

  return (
    <Select
      {...restProps}
      options={options}
      loading={loadingProps || loading}
      showSearch
      maxTagCount={0}
      placeholder={'请选择好友'}
      dropdownMatchSelectWidth={280}
      filterOption={filterOption}
      onChange={onChange}/>
  )
}

export default memo(Index)
