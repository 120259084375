import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取所有本地任务
export function local<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/local'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 记录列表
export function records<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/records'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function create<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/create'
  params.params.message = params.params.message && encodeURIComponent(params.params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建
export function createSendTasks<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/createSendTasks'
  params.params.message = params.params.message && encodeURIComponent(params.params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建平分目标的任务
export function createSplitTasks<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/createSplitTasks'
  params.params.message = params.params.message && encodeURIComponent(params.params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function update<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/update'
  params.params?.message && (params.params.message = encodeURIComponent(params.params.message))
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新
export function updates<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/updates'
  params.params.message = params.params.message && encodeURIComponent(params.params.message)
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新状态
export function updateStatus<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/updateStatus'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新状态
export function batchUpdateStatus<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/batchUpdateStatus'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function removes<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/removes'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 发送统计
export function getSendStatistic<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/getSendStatistic'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取账号循环任务统计
export function getAccountTaskCount<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/getAccountTaskCount'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取有循环任务的账号
export function getTaskAccount<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/task/getTaskAccount'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
