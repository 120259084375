import React, { memo } from 'react'
import { Form, Input, message, Modal, Image } from 'antd'
import { isMobile } from '@/utils/hooks'
import { copyText } from '@/utils'
import domain from '@/config/domain'

interface Props {
  open: boolean
  onCancel: () => void
}

const text = `
✅ <b>如何让自己的消息与众不同？</b>

1⃣️ <a href="https://${domain.host}">这是一个链接，点击可以跳转，可以放任何你想跳转的群组、频道、消息、用户、网站</a>

2⃣️ <b><a href="${domain.telegram}">加粗链接</a></b>

3⃣️ <b>加粗文本，重点凸显</b>

4⃣️ <u>这是一个带有下划线的文本</u>

5⃣️ <s>这是一个带有中划线的文本</s>

6⃣️ <i>这是斜体文本，Apple设备中文不是斜体，@${domain.telegram.split('/').pop()}，@${domain.channel.split('/').pop()}</i>

7⃣️ <code>这是一段在Apple电脑显示红色，Windows电脑上会显示褐色的文本，手机不会显示特别颜色，并且点击文本可以直接复制</code>

8⃣️ <spoiler>迷雾文本，点击后显示</spoiler>

💡 <b>只需要把特殊符号（<* href="链接">文本内容</*>）内的文本内容和链接替换成你自己的发出即可</b>
`

const Index: React.FC<Props> = (props) => {
  const { open = false, onCancel } = props

  function copy () {
    copyText(text)
    message.success('复制成功')
  }

  return (
    <Modal
      open={open}
      title={'消息美化示例'}
      centered={isMobile}
      className="common-modal example-image"
      footer={null}
      onCancel={onCancel}>
      <Form layout="vertical">
        <Form.Item label={<div>消息内容&nbsp;<a onClick={copy}>复制</a></div>}>
          <Input.TextArea
            rows={5}
            value={text.trim()}
            placeholder="请输入"/>
        </Form.Item>
        <Form.Item label={'效果图'}>
          <Image
            src={require('./img/output.png')}
            width={'100%'}
            fallback={require('@/assets/img/placeholder.png')}
            placeholder/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
