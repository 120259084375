import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取账单列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取账单类型
export function getType<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/getType'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建账单
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 创建账单
export function addVoucher<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/addVoucher'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 查找未处理订单
export function getReviewBill<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/getReviewBill'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新账单状态
export function updateStatus<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/updateStatus'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取账单收入图表数据
export function getBillIncomeChart<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/bill/getBillIncomeChart'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
