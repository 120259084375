import React, { Key, memo, useEffect, useMemo, useState } from 'react'
import { Button, Space, Badge, Table, Popconfirm, Tag, Divider, MenuProps, Dropdown, Tooltip } from 'antd'
import { PaginationProps } from 'antd/es/pagination'
import { ColumnsType, TableProps } from 'antd/es/table'
import {
  DeleteOutlined,
  ExportOutlined,
  MessageOutlined,
  PlusOutlined,
  // QuestionCircleOutlined,
  ShareAltOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import { canInviteUsers, canSendMessage, formatFilterValue } from '@/utils'
import {
  FilterValueState,
  isMobile,
  paginationProps,
  RenderTelegramName,
  useGetColumnSearchProps,
  useGetTableScrollX,
  useGetTableScrollY
} from '@/utils/hooks'
import { getActiveAccount, getActiveId } from '@/store/slice/account'
import { useSelector } from 'react-redux'
import { Chat } from '@/views/telegram/types'
import { TableRowSelection } from 'antd/lib/table/interface'
import { AccessItem } from '@/views/telegram/common'
import { SELECTION_ALL, SELECTION_NONE } from 'antd/lib/table/hooks/useSelection'
import { utils, writeFile } from 'xlsx'
import AddModal from './AddModal'
import ShareModal from './ShareModal'
import SendMessage from '@/views/telegram/SendMessage'
import dayjs from 'dayjs'
import * as tg from '../api'
import * as api from './api'

export interface ListItem extends Chat {
}

interface SelectTableProps {
  filter?: 'participant'
  onChange: (data?: AccessItem) => void
}

const Index: React.FC = () => {
  const [all, setAll] = useState<ListItem[]>([])
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({})
  const [pagination, setPagination] = useState<PaginationProps>(paginationProps)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [open, setOpen] = useState<Record<string, boolean>>({})

  const account = useSelector(getActiveAccount)
  const activeId = useSelector(getActiveId)
  const targetList = useMemo(() => {
    return all.filter(i => canSendMessage(i) && selectedRowKeys.includes(i.id))
      .map(i => ({ id: i.id, access_hash: i.access_hash }))
  }, [all, selectedRowKeys])
  const selectedList = useMemo(() => {
    return all.filter(i => selectedRowKeys.includes(i.id))
      .map(i => ({ id: i.id, access_hash: i.access_hash }))
  }, [all, selectedRowKeys])

  const { current, pageSize } = pagination
  const items: MenuProps['items'] = [
    {
      key: 'share',
      icon: <ShareAltOutlined/>,
      label: '分享群组',
      disabled: loading,
      onClick: shareChats
    },
    {
      key: 'reload',
      icon: <ReloadOutlined/>,
      label: '同步群组',
      disabled: loading,
      onClick: reload
    },
    {
      key: 'removes',
      icon: <DeleteOutlined/>,
      label: <Popconfirm
        title="你确定要删除选中记录吗？"
        onConfirm={() => removes()}>
        删除
      </Popconfirm>,
      danger: true
    },
    {
      key: 'exportExcel',
      icon: <ExportOutlined/>,
      label: '导出',
      onClick: exportExcel
    }
  ]
  const columns: ColumnsType<ListItem> = [
    {
      title: '群组名称',
      width: 200,
      dataIndex: 'title',
      filteredValue: filtered.title || null,
      render (_, record) {
        return <RenderTelegramName value={record}/>
      },
      ...useGetColumnSearchProps('title', 'text')
    },
    {
      title: '群组链接',
      width: 180,
      dataIndex: 'username',
      ellipsis: true,
      filters: [
        {
          text: '有',
          value: true
        },
        {
          text: '无',
          value: false
        }
      ],
      filteredValue: filtered.username || null,
      filterMultiple: false
    },
    {
      key: 'broadcast',
      width: 150,
      title: '群组信息',
      filters: [
        {
          text: '群组',
          value: false
        },
        {
          text: '频道',
          value: true
        },
        {
          text: '群聊',
          value: 'chat'
        }
      ],
      filteredValue: filtered.broadcast || null,
      filterMultiple: false,
      render (value, record) {
        return (
          <>
            {
              record._ === 'chat'
                ? <Tag color="error">群聊</Tag>
                : record.broadcast
                  ? <Tag color="warning">频道</Tag>
                  : <Tag color="processing">群组</Tag>
            }
            {
              record.creator
                ? <Tag color="success">创建者</Tag>
                : record.admin_rights ? <Tag color="success">管理员</Tag> : null
            }
          </>
        )
      }
    },
    {
      title: '消息限制',
      width: 140,
      dataIndex: 'slowmode_enabled',
      filters: [
        {
          text: '允许发送',
          value: true
        },
        {
          text: '禁止发送',
          value: false
        }
      ],
      filteredValue: filtered.slowmode_enabled || null,
      filterMultiple: false,
      render: renderMessageLimit
    },
    {
      key: 'inviteUsers',
      title: '邀请入群',
      width: 100,
      filters: [
        {
          text: '允许',
          value: true
        },
        {
          text: '禁止',
          value: false
        }
      ],
      filteredValue: filtered.inviteUsers || null,
      filterMultiple: false,
      render (value, record) {
        return !canInviteUsers(record) && <Tag color="error">禁止</Tag>
        // return canInviteUsers(record)
        //   ? <Tag color="success">允许</Tag>
        //   : <Tag color="error">禁止</Tag>
      }
    },
    {
      title: '群人数',
      width: 100,
      dataIndex: 'participants_count'
    },
    {
      title: '加入时间',
      width: 164,
      dataIndex: 'date',
      render (value) {
        return dayjs(value * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      title: '操作',
      width: 120,
      fixed: 'right',
      render (_, record) {
        return <>
          <a onClick={() => sendMessage(record)} className={!canSendMessage(record) ? 'disabled' : ''}>
            发送消息
          </a>
          <Divider type={'vertical'}/>
          <Popconfirm
            title="你确定要删除这条记录吗？"
            placement="topRight"
            onConfirm={() => removes(record)}>
            <a>删除</a>
          </Popconfirm>
        </>
      }
    }
  ]
  const rowSelection: TableRowSelection<ListItem> = {
    fixed: true,
    selections: [SELECTION_ALL, SELECTION_NONE],
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange (keys) {
      setSelectedRowKeys(keys as string[])
    }
  }
  const scrollX = useGetTableScrollX(columns, 32)
  const scrollY = useGetTableScrollY(list.length)

  useEffect(getList, [activeId])
  useEffect(getPageList, [all, current, pageSize, filtered])

  // 获取列表
  function getList (getAll?: boolean) {
    if (!activeId) return
    const params = {
      [getAll ? '_id' : 'account']: activeId
    }
    const apiFn = getAll ? tg.getAllChats : api.all
    setLoading(true)
    apiFn<ListItem[]>(params).then(({ data, success }) => {
      if (success) {
        setAll(data.sort((a, b) => a.date - b.date))
        setSelectedRowKeys([])
        setPagination(state => ({
          ...state,
          current: 1
        }))
      }
    }).finally(() => setLoading(false))
  }

  // 获取分页数据
  function getPageList () {
    let list: ListItem[]
    const page = current!
    const skip = (page - 1) * pageSize!
    const limit = page * pageSize!
    const filters = formatFilterValue(filtered)
    const filterKeys = Object.keys(filters)

    if (filterKeys.length) {
      list = all.filter(item => {
        // 每一条数据都需要满足所有筛选条件彩返回
        const checks: boolean[] = []

        filterKeys.forEach(key => {
          const filterValue: any = filters[key]
          switch (key) {
            case 'title':
              checks.push(item.title.includes(filterValue))
              break
            case 'username':
              checks.push(!!item.username === filterValue)
              break
            case 'broadcast':
              checks.push(filterValue === 'chat' ? item._ === filterValue : item.broadcast === filterValue)
              break
            case 'inviteUsers':
              checks.push(canInviteUsers(item) === filterValue)
              break
            case 'slowmode_enabled':
              checks.push(canSendMessage(item) === filterValue)
              break
          }
        })

        return checks.every(item => item)
      })
    } else {
      list = all
    }

    setList(list.slice(skip, limit))
    setPagination(state => ({
      ...state,
      total: list.length
    }))
  }

  // 同步官方群组列表
  function reload () {
    getList(true)
  }

  function removes (record?: ListItem) {
    if (!record && !selectedList.length) return
    const params = {
      _id: activeId,
      channels: record
        ? [{
          id: record.id,
          access_hash: record.access_hash
        }]
        : selectedList
    }
    return tg.leaveChannels(params).then(({ success }) => {
      success && getList()
    })
  }

  // 分享群组
  function shareChats () {
    if (!all.length) return
    setOpen({ share: true })
  }

  // 导出列表
  function exportExcel () {
    const sheetTitle = [['群组名称', '群组链接', '群组信息', '消息限制', '邀请入群']]
    const sheetData = all.map(item => ({
      title: item.title,
      username: item.username,
      broadcast: item._ === 'chat' ? '私密群组' : (item.broadcast ? '频道' : '群组'),
      slowmode_enabled: item.slowmode_enabled ? '慢发言' : (canSendMessage(item) ? '' : '禁止发言'),
      inviteUsers: canInviteUsers(item) ? '' : '禁止'
    }))
    const workbook = utils.book_new()
    const worksheet = utils.json_to_sheet(sheetData)
    const maxWidth = sheetData.reduce((width, item) => Math.max(width, item.title.length * 2), 30)
    worksheet['!cols'] = [
      { wch: maxWidth },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ]
    utils.sheet_add_aoa(worksheet, sheetTitle, { origin: 'A1' })
    utils.book_append_sheet(workbook, worksheet, '群组列表')
    writeFile(workbook, `群组列表_${account!.user.phone}.xlsx`, { compression: true })
  }

  function sendMessage (record?: ListItem) {
    if (record) setSelectedRowKeys([record.id])
    setOpen({ sendMessage: true })
  }

  function resetFilter () {
    getList()
    setFiltered({})
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters) => {
    setFiltered(filters)
    setPagination(state => ({
      ...state,
      ...pagination
    }))
  }

  return (
    <div className="common">
      <Space className="common__header" wrap>
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => setOpen({ add: true })}>
          添加群组
        </Button>
        {
          isMobile
            ? <Badge count={targetList.length} size={'small'}>
              <Dropdown.Button
                type={'primary'}
                menu={{ items }}
                onClick={() => sendMessage()}>
                <MessageOutlined/>
                <span>发送消息</span>
              </Dropdown.Button>
            </Badge>
            : <>
              <Badge count={targetList.length} size={'small'} overflowCount={999}>
                <Button type="primary" icon={<MessageOutlined/>} onClick={() => sendMessage()}>
                  发送消息
                </Button>
              </Badge>
              <Badge count={selectedRowKeys.length} size={'small'} overflowCount={999}>
                <Button type="primary" icon={<ShareAltOutlined/>} onClick={shareChats}>
                  分享群组
                </Button>
              </Badge>
              <Tooltip title={'如出现同步后群组数量为0，请点击右上角客服图标进群查看设置教程'}>
                <Button icon={<ReloadOutlined/>} disabled={loading} onClick={reload}>
                  同步群组
                </Button>
              </Tooltip>
              <Popconfirm
                title="你确定要删除选中记录吗？"
                onConfirm={() => removes()}>
                <Badge count={selectedList.length} size={'small'} overflowCount={999}>
                  <Button icon={<DeleteOutlined/>} type="dashed" danger>
                    删除
                  </Button>
                </Badge>
              </Popconfirm>
              <Button icon={<ExportOutlined/>} onClick={exportExcel}>
                导出
              </Button>
            </>
        }
        <Button onClick={resetFilter} disabled={loading}>
          重置筛选
        </Button>
        {/* <Tooltip */}
        {/*   title={ */}
        {/*     <div> */}
        {/*       <span>如果出现同步群组数量为0，请点</span> */}
        {/*       <a href="https://t.me/Y8TGChannel/32" rel={'noreferrer'} target={'tglink'}>此处查看</a> */}
        {/*       <span>解决办法</span> */}
        {/*     </div> */}
        {/*   } */}
        {/* > */}
        {/*   <Button type={'text'} icon={<QuestionCircleOutlined/>}/> */}
        {/* </Tooltip> */}
      </Space>
      <Table
        rowKey="id"
        scroll={{ x: scrollX, y: scrollY }}
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={pagination}
        rowSelection={rowSelection}
        onChange={onChange}/>
      <AddModal
        open={open.add}
        onSuccess={getList}
        onCancel={() => setOpen({})}
      />
      <ShareModal
        open={open.share}
        list={all}
        selectedRowKeys={selectedRowKeys}
        onCancel={() => setOpen({})}
      />
      <SendMessage
        open={open.sendMessage}
        target={'peerChannel'}
        targetList={targetList}
        canChangeTargetList
        onClose={() => setOpen({})}/>
    </div>
  )
}

const _ChannelSelectTable: React.FC<SelectTableProps> = (props) => {
  const { filter, onChange } = props
  const [list, setList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const activeId = useSelector(getActiveId)
  const filtered = useMemo(() => {
    let _list
    switch (filter) {
      case 'participant':
        _list = list.filter(item => item._ !== 'chat' && (!item.broadcast || !!item.admin_rights))
        break
      default:
        _list = list
    }
    return _list.map(item => ({
      id: item.id,
      title: item.title,
      username: item.username,
      access_hash: item.access_hash
    }))
  }, [list, filter])

  const columns: ColumnsType<typeof filtered[number]> = [
    {
      title: '群组名称',
      ellipsis: true,
      dataIndex: 'title',
      render (_, record) {
        return <RenderTelegramName value={record}/>
      },
      ...useGetColumnSearchProps('title', 'text', true)
    }
  ]
  const rowSelection: TableRowSelection<typeof filtered[number]> = {
    type: 'radio',
    selectedRowKeys,
    onChange: setSelectedRowKeys
  }
  const scrollY = useGetTableScrollY(filtered.length, 0)

  useEffect(getList, [activeId])
  useEffect(selectFirst, [filtered])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectOnchange, [filtered, selectedRowKeys])

  // 获取列表
  function getList () {
    if (!activeId) return
    const params = {
      account: activeId
    }
    setLoading(true)
    api.all(params).then(({ data, success }) => {
      if (success) {
        setList(data)
        setSelectedRowKeys([])
      }
    }).finally(() => setLoading(false))
  }

  // 默认选中第一个群
  function selectFirst () {
    const first = filtered[0]
    setSelectedRowKeys(first ? [first.id] : [])
  }

  function selectOnchange () {
    const item = filtered.find(i => i.id === selectedRowKeys[0])
    onChange(item && {
      id: item.id,
      title: item.title,
      access_hash: item.access_hash
    })
  }

  const onRow: TableProps<typeof filtered[number]>['onRow'] = (record) => {
    return {
      onClick () {
        setSelectedRowKeys([record.id])
      }
    }
  }

  return (
    <Table
      rowKey="id"
      scroll={{ x: '100%', y: scrollY }}
      loading={loading}
      columns={columns}
      dataSource={filtered}
      pagination={false}
      rowSelection={rowSelection}
      rowClassName={'cursor'}
      onRow={onRow}
    />
  )
}

const ChannelSelectTable = memo(_ChannelSelectTable)

export function renderMessageLimit (value: boolean, record: Chat) {
  const banned = record.default_banned_rights
  return <>
    {
      canSendMessage(record)
        ? banned &&
        (
          banned.send_media
            ? <Tag color="warning">禁图片</Tag>
            : banned.embed_links && <Tag color="warning">禁文字</Tag>
        )
        : <Tag color="error">禁止</Tag>
    }
    {
      value && <Tag color="default">慢速</Tag>
    }
  </>
}

export { ChannelSelectTable }
export default Index
