import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { Modal, Form, Input, Segmented, message } from 'antd'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { DelayFormItem } from '@/views/telegram/SendMessage'
import { resultModal, ResultItem } from '@/views/telegram/common'
import { useSelector } from 'react-redux'
import { getActiveId, getOptions } from '@/store/slice/account'
import { SegmentedProps } from 'antd/es/segmented'
import { PhoneOutlined, IdcardOutlined } from '@ant-design/icons'
import { UsernameFormItem } from '@/views/telegram/channel/AddModal'
import FormAccountSelect from '@/views/telegram/FormAccountSelect'
import * as tg from '@/views/telegram/api'
import * as taskApi from '@/views/task/api'

interface Props {
  open: boolean
  onSuccess: () => void
  onCancel: () => void
}

interface FormValues {
  _id: string[]
  delay: string
  delayUnit: string
  phone?: string
  username?: string
}

const Index: React.FC<Props> = (props) => {
  const { open = false, onSuccess, onCancel } = props
  const [tab, setTab] = useState<'phone' | 'username'>('username')
  const [loading, setLoading] = useState<boolean>(false)

  const options = useSelector(getOptions)
  const activeId = useSelector(getActiveId)

  const [form] = Form.useForm()
  const tabOptions: SegmentedProps['options'] = [
    {
      label: '用户名',
      value: 'username',
      icon: <IdcardOutlined/>
    },
    {
      label: '手机号',
      value: 'phone',
      icon: <PhoneOutlined/>
    }
  ]

  useEffect(toggleVisible, [open, form, activeId])

  function toggleVisible () {
    form.setFieldValue('_id', activeId && [activeId])
  }

  function phoneOnBlur (e: ChangeEvent<HTMLTextAreaElement>) {
    let value: string | string[] = e.target.value
    // 分割符号，替换成换行
    value = value.replace(/[,，\s\n]/g, '\n')
    // 去除非数字
    value = value.replace(/[^\d\n]/g, '')
    // 去除空值
    value = value.split('\n').filter(Boolean)
    // 去重
    value = [...Array.from(new Set(value))].join('\n')
    form.setFieldValue('phone', value)
  }

  function onFinish (values: FormValues) {
    const phone = values.phone?.split('\n')
    const username = values.username?.split('\n')
    const params = {
      ...values,
      phone: undefined,
      username: undefined,
      targetList: (tab === 'username' ? username : phone) || []
    }

    setLoading(true)

    if (username?.length === 1 || tab === 'phone') {
      online().finally(() => setLoading(false))
    } else {
      task().finally(() => setLoading(false))
    }

    function task () {
      const task = {
        action: 'addContactsByUsername',
        params
      }
      return taskApi.create(task).then(({ success }) => {
        if (success) {
          onCancel()
          message.success('任务添加成功')
        }
      })
    }

    function online () {
      const apiFn = tab === 'phone' ? tg.importContacts : tg.addContactsByUsername
      return apiFn(params)
        .then(({ success, data }) => {
          if (success) {
            onCancel()
            onSuccess()
            const targetList = options.filter(item => params._id.includes(item.value as string))
            const resultList: ResultItem[] = targetList.map((item, index) => ({
              label: item.label as string,
              value: data[index] as string[]
            }))
            resultModal(resultList)
          }
        })
    }
  }

  return (
    <Modal
      open={open}
      // @ts-ignore
      title={<Segmented value={tab} options={tabOptions} onChange={setTab}/>}
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={onCancel}>
      <Form
        name="add"
        form={form}
        layout="vertical"
        initialValues={{ _id: activeId && [activeId], delay: 3, delayUnit: 'seconds' }}
        onFinish={onFinish}>
        <FormAccountSelect form={form}/>
        {
          tab === 'username' &&
          <DelayFormItem
            min={1}
            tooltip={'目标数量过多时请提高单个间隔'}
            timeUnitList={['seconds']}/>
        }
        {
          tab === 'username'
            ? <UsernameFormItem max={500} form={form} label={'用户名'}/>
            : <Form.Item
              name="phone"
              label="手机号"
              rules={requiredInputRule}>
              <Input.TextArea
                rows={5}
                placeholder={'请输入手机号，多个请用空格/逗号/换行分隔，Telegram官方服务号：42777'}
                onBlur={phoneOnBlur}
              />
            </Form.Item>
        }
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
