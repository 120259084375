import React, { useState, memo, useMemo, useEffect } from 'react'
import { Modal, Form, Input, Select, Divider, InputNumber, notification, message, Space } from 'antd'
import { addressRule, isMobile, requiredInputRule } from '@/utils/hooks'
import { copyText } from '@/utils'
import { useSelector } from 'react-redux'
import { getTronList, selectAll } from '@/store/slice/tron'
import { useAppDispatch } from '@/store'
import { ListItem } from './index'
import * as api from './api'

interface Props {
  open: boolean
  onCancel: () => void
  onSuccess?: (data: ListItem) => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, onSuccess, onCancel } = props
  const dispatch = useAppDispatch()
  const [type, setType] = useState<'trx' | 'usdt'>('usdt')
  const [loading, setLoading] = useState<boolean>(false)
  const [accountId, setAccountId] = useState<string>()

  const list = useSelector(selectAll)
  const account = useMemo(() => list.find(i => i._id === accountId), [list, accountId])
  const [form] = Form.useForm()

  useEffect(toggleVisible, [open, list, dispatch])

  function toggleVisible () {
    if (open) {
      !list.length && dispatch(getTronList())
    } else {
      setType('usdt')
      setAccountId(undefined)
    }
  }

  function typeOnchange (v: 'trx' | 'usdt') {
    form.resetFields(['amount'])
    setType(v)
  }

  function accountOnchange (v: string) {
    form.resetFields(['amount'])
    setAccountId(v)
  }

  function copy (text: string) {
    copyText(text)
    message.success('复制成功')
  }

  function onFinish (values: Record<string, any>) {
    setLoading(true)
    api.transfer(values).then(({ success, data }) => {
      if (success) {
        onCancel()
        onSuccess?.(account!)
        notification.success({
          duration: 0,
          message: '转账成功',
          description: <pre className={'code'} onClick={() => copy(data)}>{data}</pre>
        })
      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      title={'转账'}
      className="common-modal"
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={() => form.submit()}
      onCancel={onCancel}>
      <Form
        name="transfer"
        form={form}
        layout="vertical"
        initialValues={{ type: 'usdt' }}
        onFinish={onFinish}>
        <Form.Item name="_id" label="钱包" rules={requiredInputRule}>
          <Select
            placeholder="请选择"
            onChange={accountOnchange}>
            {
              list.map(item => (
                <Select.Option
                  key={item._id}
                  value={item._id}>
                  <span>{item.name}</span>
                  <Divider type={'vertical'}/>
                  <span>USDT：{+item.usdt.toFixed(1)}</span>
                  <Divider type={'vertical'}/>
                  <span>TRX：{+item.trx.toFixed(1)}</span>
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label="金额" required>
          <Space.Compact block>
            <Form.Item
              name="amount"
              label={'金额'}
              noStyle
              rules={[...requiredInputRule, { min: 1, type: 'number' }]}
              validateFirst>
              <InputNumber
                max={account && account[type]}
                style={{ width: 'calc(100% - 80px)' }}
                pattern="\d\.*"
                className={'input-group__auto'}
                placeholder="请输入"/>
            </Form.Item>
            <Form.Item name="type" noStyle rules={requiredInputRule}>
              <Select
                style={{ width: 80 }}
                placeholder="请选择"
                onChange={typeOnchange}>
                <Select.Option value="usdt">USDT</Select.Option>
                <Select.Option value="trx">TRX</Select.Option>
              </Select>
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item name="to" label="转账地址" rules={addressRule}>
          <Input maxLength={34} placeholder="请输入"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
