import React, { memo, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Space,
  Radio
} from 'antd'
import { useSelector } from 'react-redux'
import { requiredInputRule } from '@/utils/hooks'
import { getActiveId } from '@/store/slice/account'
import { ListItem } from '@/views/task'
import { AccessItem, SuccessTag } from '@/views/telegram/common'
import { FwdMessage } from '@/views/telegram/message'
import { Chat, Target } from '@/views/telegram/types'
import { ListItem as Contact } from '@/views/telegram/contact'
import { ListItem as Channel } from '@/views/telegram/channel'
import { Rule } from 'antd/es/form'
import { initialValues as _initialValues, TimerFormItem, TargetFormItem } from '@/views/telegram/SendMessage'
import FormAccountSelect from '@/views/telegram/FormAccountSelect'
import dayjs from 'dayjs'
import * as tg from '@/views/telegram/api'
import * as taskApi from '@/views/task/api'
import { translate } from '@/utils/translate'

interface Props {
  open: boolean
  record?: ListItem
  fwdMessage?: FwdMessage
  multiAccount?: boolean
  updateSuccess?: (data: ListItem) => void
  onSuccess?: () => void
  onClose: () => void
}

interface FormValues {
  _id: string
  delay: number
  maxDelay?: number
  loop: boolean
  link?: string
  target: Target
  interval: number
  maxInterval?: number
  delayUnit: 'seconds' | 'minute'
  intervalUnit: 'seconds' | 'minute' | 'hour' | 'day'
  dropAuthor: boolean
  targetIds: string[]
  filterChat: boolean
}

let fwdMessages: FwdMessage[] = []
const initialValues: Partial<FormValues> = {
  ..._initialValues,
  target: 'peerChannel',
  dropAuthor: false
}

const Index: React.FC<Props> = (props) => {
  const { open = false, record, fwdMessage, multiAccount, onSuccess, updateSuccess, onClose } = props
  const [list, setList] = useState<Array<Contact | Channel>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(true)

  const activeId = useSelector(getActiveId)
  const _fwdMessage = record?.params.fwdMessage || fwdMessage
  const fwdMessageLink = _fwdMessage && `https://t.me/${_fwdMessage.from_peer.username || `c/${_fwdMessage.from_peer.id}`}/${_fwdMessage.id[0]}`

  const [form] = Form.useForm()
  const linkRule: Rule[] = [
    {
      required: true,
      pattern: /^https:\/\/t\.me\/[a-zA-Z][a-zA-Z0-9_]{4,32}\/\d+$/,
      message: '消息链接不正确',
      validateTrigger: 'onChange'
    },
    {
      validator (rule, value) {
        const _id = form.getFieldValue('_id')
        const _ids = Array.isArray(_id) ? _id : [_id]
        if (!_id || value === fwdMessageLink) {
          return Promise.resolve()
        } else {
          return getChannelInfo(_ids, value)
        }
      },
      validateTrigger: 'onSubmit'
    }
  ]

  useEffect(toggleVisible, [form, open, record, activeId, multiAccount, fwdMessageLink])

  function toggleVisible () {
    if (open && record) {
      const { params } = record
      form.setFieldsValue({
        ...params,
        link: fwdMessageLink,
        time: params.time?.length ? [dayjs(params.time[0]), dayjs(params.time[1])] : params.time,
        stopDate: params.stopDate && dayjs(params.stopDate),
        targetIds: params.targetList.map((i: AccessItem) => i.id)
      })
      setDisabled(!params.loop)
    } else if (open) {
      form.setFieldValue('_id', multiAccount && activeId ? [activeId] : activeId)
      setDisabled(true)
    } else {
      fwdMessages = []
    }
  }

  function getChannelInfo (_id: string[], link: string) {
    const linkPart = link.split('/')
    const messageId = linkPart.pop()!
    const username = linkPart.pop()!
    const params = {
      _id,
      username
    }

    return tg.resolveChannelUsername<Chat[]>(params).then(({ data, success, message }) => {
      if (success) {
        fwdMessages = data.map(item => ({
          id: [+messageId],
          from_peer: {
            _: 'peerChannel',
            id: item.id,
            username,
            access_hash: item.access_hash
          }
        }))
      } else {
        return Promise.reject(translate(message))
      }
    })
  }

  function onFinish (values: FormValues) {
    const { link, targetIds = [], ...rest } = values
    const params = {
      ...record?.params,
      ...rest,
      fwdMessage,
      targetList: list.filter(i => targetIds.includes(i.id)).map(i => ({
        id: i.id,
        access_hash: i.access_hash
      }))
    }
    const execType = !record && !multiAccount && !params.loop && params.targetList.length === 1 && 'online'

    if (!params.targetList.length && !multiAccount) return

    setLoading(true)

    const apiFn = execType === 'online' ? online : task

    apiFn(params).then(success => success && onClose()).finally(() => setLoading(false))

    function online (params: ListItem['params']) {
      return tg.forwardMessages<SuccessTag[]>(params).then(({ data, success }) => {
        if (success) {
          message.success(`成功发送 ${data.filter(i => /^\d+$/.test(String(i))).length} 条消息`)
        }
        return success
      })
    }

    function task (params: ListItem['params']) {
      return record ? update() : add()

      function add () {
        const task: Partial<ListItem> = {
          action: 'forwardMessages',
          params: {
            ...params,
            target: params.target || 'peerChannel',
            // @ts-ignore
            fwdMessage: multiAccount ? fwdMessages : fwdMessage
          }
        }
        const apiFn = multiAccount ? taskApi.createSendTasks : taskApi.create

        return apiFn(task).then(({ data, success }) => {
          if (success) {
            onSuccess?.()
            message.success('任务添加成功')
          }
          return success
        })
      }

      function update () {
        const update = {
          _id: record!._id,
          params: {
            ...params,
            fwdMessage: fwdMessages[0] || record!.params.fwdMessage
          }
        }
        return taskApi.update(update).then(({ data, success, message: msg }) => {
          if (success) {
            message.success(msg)
            updateSuccess?.(data)
          }
          return success
        })
      }
    }
  }

  const DrawerFooter = <Space>
    <Button onClick={onClose}>取消</Button>
    <Button
      type={'primary'}
      loading={loading}
      onClick={form.submit}>提交</Button>
  </Space>

  return (
    <Drawer
      title={'转发消息'}
      open={open}
      closable={false}
      placement="right"
      rootClassName={'drawer-720'}
      footerStyle={{ textAlign: 'right' }}
      forceRender
      footer={DrawerFooter}
      onClose={onClose}
      afterOpenChange={v => !v && form.resetFields()}>
      <Form
        name="forward"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center'
        }}
        onFinish={onFinish}>
        <FormAccountSelect form={form} hidden={!multiAccount} showNoTask/>
        <TargetFormItem
          open={open}
          form={form}
          target={record?.params.target || initialValues.target}
          account={record?.params._id || activeId}
          banChange={!!record}
          onlyTarget={multiAccount}
          setTargetList={setList}
        />
        <Row gutter={20}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            {
              record || multiAccount
                ? <Form.Item
                  name={'link'}
                  label="消息链接"
                  rules={linkRule}
                  tooltip={'公开群组或频道的消息链接'}
                  hasFeedback
                  dependencies={['_id']}
                  validateFirst>
                  <Input maxLength={50} placeholder={'请输入'}/>
                </Form.Item>
                : <Form.Item label="消息链接">
                  <a href={fwdMessageLink} target={'tgLink'}>
                    {fwdMessageLink}
                  </a>
                </Form.Item>
            }
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="dropAuthor"
              label="原消息链接"
              rules={requiredInputRule}
              tooltip={'删除原消息链接后，转发的消息跟自己发送的消息一样'}>
              <Radio.Group>
                <Radio value={false}>保留</Radio>
                <Radio value={true}>删除</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <TimerFormItem form={form} record={record} disabled={disabled} setDisabled={setDisabled}/>
      </Form>
    </Drawer>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
