import React, { memo, useEffect, useState } from 'react'
import {
  Button,
  Drawer,
  Form,
  Space,
  Upload,
  Row,
  Col, Radio
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { AxiosRequestConfig } from 'axios'
import { RcFile } from 'antd/es/upload'
import { Entity, Message, Photo, SavePhoto, Target } from '@/views/telegram/types'
import { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { requiredInputRule } from '@/utils/hooks'
import { getActiveId } from '@/store/slice/account'
import { transformArrayBufferToBase64Image, validaImageFile } from '@/utils'
import { findSender } from '@/views/telegram/message/index'
import { MessageFormItem } from '@/views/telegram/SendMessage'

import * as tg from '@/views/telegram/api'
import ImagePreview from '@/components/ImagePreview'

interface Props {
  open: boolean
  target?: Target
  record: Message
  onSuccess: () => void
  onClose: () => void
}

type FileData = Photo | SavePhoto

const entityTag: Record<Entity['_'], string> = {
  messageEntityPre: '<pre>placeholder</pre>',
  messageEntityCode: '<code>placeholder</code>',
  messageEntityBold: '<b>placeholder</b>',
  messageEntityItalic: '<i>placeholder</i>',
  messageEntityStrike: '<s>placeholder</s>',
  messageEntityTextUrl: '<a href="#">placeholder</a>',
  messageEntitySpoiler: '<spoiler>placeholder</spoiler>',
  messageEntityUnderline: '<u>placeholder</u>'
}

const entitiesType = Object.keys(entityTag) as Entity['_'][]

function setEntities (message: string, entities: Entity[] = []) {
  let addLength = 0
  let tagMessage = message
  const entitiesList: Entity[][] = []

  // 过滤出支持的消息实体
  entities = entities.filter(i => entitiesType.includes(i._))

  // 将相同位置/包含关系的消息实体合并为一个二维数据
  entities.forEach((current, oIndex) => {
    const same = [current]
    entities.slice(oIndex + 1).forEach((next, iIndex) => {
      const sameSub = current.offset === next.offset && current.length === next.length
      if (sameSub) {
        same.push(next)
        // 将后面相通位置的消息实体删除
        entities.splice(oIndex + 1 + iIndex, 1)
      }
    })
    entitiesList.push(same)
  })

  // 将消息实体排序，主要是将包含关系中的offset大的排在前面，替换tag时先处理
  // offset大就意味着在包含关系中，是最后一个标签
  entitiesList.sort((a, b) => {
    const a1 = a[0]
    const b1 = b[0]
    const sort = (a1.offset + a1.length) - (b1.offset + b1.length)
    if (sort === 0) return b1.offset - a1.offset
    return sort
  })

  // 将支持的消息实体，添加上标签，并替换掉原来的文字，
  // 因为添加了标签，所以原来的字符长度会变化，需要加上每次变化的length
  entitiesList.forEach(replaceMessage)

  return tagMessage

  function replaceMessage (entities: Entity[]) {
    const entity = entities[0]
    const start = entity.offset && entity.offset + addLength
    const end = entity.offset + entity.length + addLength
    const matched = tagMessage.substring(start, end)
    const tag = entities.reduce(getEntityTag, matched)

    tagMessage = tagMessage.replaceAll(matched, (substring, index) => {
      if (index === entity.offset || index === addLength + entity.offset) {
        addLength += tag.length - substring.length
        return tag
      }
      return substring
    })
  }

  function getEntityTag (string: string, entity: Entity) {
    let tag = entityTag[entity._]
    if (entity._ === 'messageEntityTextUrl') {
      tag = tag.replace('#', String(entity.url))
    }
    return tag.replace('placeholder', string)
  }
}

const Index: React.FC<Props> = (props) => {
  const { open = false, record, onSuccess, onClose } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [fileData, setFileData] = useState<FileData>()
  const [previewUrl, setPreviewUrl] = useState<string>()

  const activeId = useSelector(getActiveId)

  const [form] = Form.useForm()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(toggleVisible, [open, record])

  function toggleVisible () {
    if (open && record) {
      form.setFieldsValue({
        message: setEntities(record.message, record.entities),
        previewLink: record.media?._ === 'messageMediaWebPage'
      })
      record.media?.photo &&
      getUploadedFileList(record.media.photo)
    } else {
      setFileList([])
      setFileData(undefined)
    }
  }

  // 获取已上传图片
  function getUploadedFileList (_photo: Required<Message>['media']['photo']) {
    const photo: SavePhoto = {
      id: _photo.id,
      access_hash: _photo.access_hash,
      file_reference: Object.values(_photo.file_reference),
      thumb_size: _photo.sizes.filter(i => i._ === 'photoSize').at(-1)!.type
    }
    const getPhotoParams = {
      ...photo,
      _id: activeId
    }
    tg.getPhotoFile(getPhotoParams).then(data => {
      setFileList(state => [
        ...state,
        {
          name: photo.id + '.jpeg',
          uid: photo.id,
          url: transformArrayBufferToBase64Image(data),
          status: 'success'
        }
      ])
    })
    setFileData(photo)
  }

  // 图片预览
  function onPreview (file: UploadFile) {
    let url: string
    if (file.status === 'success') {
      url = file.url!
    } else {
      url = URL.createObjectURL(file.originFileObj as Blob)
    }
    setPreviewUrl(url)
  }

  // 上传文件
  const customRequest: UploadProps['customRequest'] = (options) => {
    validaImageFile(options.file as RcFile).then(() => {
      const params = {
        _id: activeId,
        file: options.file as RcFile
      }
      const config: AxiosRequestConfig = {
        onUploadProgress: options.onProgress
      }

      tg.uploadMedia<Photo>(params, config).then(({ success, data, message }) => {
        if (success) {
          options.onSuccess!(success)
          setFileData(data)
        } else {
          return Promise.reject(new Error(message))
        }
      })
    }).catch(options.onError)
  }

  // 删除文件
  const onRemove: UploadProps['onRemove'] = () => {
    setFileData(undefined)
  }

  function onFinish (values: Record<string, any>) {
    const sender = findSender(record.peer_id)
    const photo = fileData && {
      id: fileData.id,
      access_hash: fileData.access_hash,
      file_reference: fileData.file_reference,
      thumb_size: (fileData as SavePhoto).thumb_size || (fileData as Photo).sizes.filter(i => (i._ = 'photoSize')).pop()!.type
    }
    const params = {
      ...values,
      _id: activeId,
      id: record.id,
      peer: {
        _: record.peer_id._,
        id: sender.id,
        access_hash: sender.access_hash
      },
      photo
    }
    setLoading(true)
    tg.editMessage(params)
      .then(({ success }) => {
        if (success) {
          onClose()
          onSuccess()
        }
      })
      .finally(() => setLoading(false))
  }

  const DrawerFooter = <Space>
    <Button onClick={onClose}>取消</Button>
    <Button
      type={'primary'}
      loading={loading}
      onClick={form.submit}>提交</Button>
  </Space>

  return (
    <Drawer
      title={'编辑消息'}
      open={open}
      closable={false}
      placement="right"
      rootClassName={'drawer-720'}
      footerStyle={{ textAlign: 'right' }}
      forceRender
      footer={DrawerFooter}
      onClose={onClose}
      afterOpenChange={v => !v && form.resetFields()}>
      <Form
        name="edit"
        form={form}
        layout="vertical"
        initialValues={{ previewLink: false }}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center'
        }}
        onFinish={onFinish}>
        <Row gutter={20}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              label="消息图片"
              extra="请上传JPG/PNG图片，并且不大于512KB"
              tooltip="原消息如果没有图片，编辑图片不会生效">
              <Upload
                disabled={!record?.media?.photo}
                accept="image/png,image/jpeg,image/gif"
                maxCount={1}
                fileList={fileList}
                listType="picture-card"
                onChange={info => setFileList(info.fileList)}
                onRemove={onRemove}
                onPreview={onPreview}
                customRequest={customRequest}>
                <div>
                  <PlusOutlined/>
                  <div>选择图片</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item
              name="previewLink"
              label="链接预览"
              rules={requiredInputRule}
              tooltip={'图片消息无效，官方默认不显示'}>
              <Radio.Group>
                <Radio value={true}>开启</Radio>
                <Radio value={false}>关闭</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <MessageFormItem/>
      </Form>
      <ImagePreview url={previewUrl} setUrl={setPreviewUrl}/>
    </Drawer>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
