import React, { Key, memo, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Space,
  Table,
  Tag,
  Row,
  Col,
  Select,
  SelectProps,
  Image,
  Divider,
  message,
  Badge,
  Dropdown,
  MenuProps
} from 'antd'
import { PaginationProps } from 'antd/es/pagination'
import { ColumnsType, TableProps } from 'antd/es/table'
import {
  ExportOutlined,
  MessageOutlined,
  NotificationOutlined,
  PaperClipOutlined,
  PlusOutlined,
  VideoCameraOutlined,
  SyncOutlined
} from '@ant-design/icons'
import {
  FilterValueState,
  isMobile,
  paginationProps,
  useAuthsSelector,
  useGetTableScrollY,
  RenderTelegramName,
  useGetColumnSearchProps
} from '@/utils/hooks'
import { getActiveId } from '@/store/slice/account'
import { useSelector } from 'react-redux'
import { exportRaw, formatFilterValue } from '@/utils'
import { User, Chat, Message, Target } from '@/views/telegram/types'
import { ChannelSelectTable } from '@/views/telegram/channel'
import { ContactSelectTable } from '@/views/telegram/contact'
import { AccessItem, messageTypeOptions } from '@/views/telegram/common'
import SendMessage from '@/views/telegram/SendMessage'
import EditMessage from './EditMessage'
import ForwardMessage from '@/views/telegram/ForwardMessage'
import FormContactSelect from '@/views/telegram/FormContactSelect'
import FormChannelSelect, { SelectOption } from '@/views/telegram/FormChannelSelect'
import dayjs from 'dayjs'
import * as tg from '../api'
import scss from './index.module.scss'
import AddContact from '@/views/telegram/search/AddContact'
import Invite from '@/views/telegram/contact/Invite'
import { SELECTION_ALL, SELECTION_NONE } from 'antd/lib/table/hooks/useSelection'

interface ListItem extends Message {
}

interface Sender {
  id: string
  phone?: string
  title?: string
  first_name?: string
  last_name?: string
  username?: string
  access_hash: string
  canEdit: boolean
  noforwards: ListItem['noforwards']
}

export interface History {
  messages: Message[]
  chats: Chat[]
  users: User[]
  count: number
}

interface MessageProps {
  item: Message
  users?: User[]
  chats?: Chat[]
  editMessage?: (record: ListItem) => void
  forwardMessage?: (id: ListItem['id']) => void
}

export interface FwdMessage {
  id: number[]
  from_peer: {
    _: Message['peer_id']['_']
    id: string
    username?: string
    access_hash: string
  }
}

let users: User[] = []
let chats: Chat[] = []
let messages: Message[] = []
// 保存媒体组的媒体文件/消息id/文本消息
let mediaGroupMap: Record<string, Required<ListItem>['media'][]> = {}

// 查找消息发送人信息
export function findSender (peer: ListItem['peer_id'], _users = users, _chats = chats) {
  let sender: Sender = {
    id: '-',
    first_name: '-',
    last_name: '-',
    username: '-',
    access_hash: '-',
    noforwards: true,
    canEdit: false
  }

  if (peer._ === 'peerUser') {
    const user = _users.find(item => item.id === peer.user_id)
    if (user) {
      sender = {
        id: user.id,
        phone: user.phone,
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        access_hash: user.access_hash,
        noforwards: false,
        canEdit: user.self
      }
    }
  } else {
    const chat = _chats.find(item => item.id === peer[peer._ === 'peerChannel' ? 'channel_id' : 'chat_id'])
    if (chat) {
      sender = {
        id: chat.id,
        title: chat.title,
        username: chat.username,
        access_hash: chat.access_hash,
        noforwards: chat.noforwards,
        canEdit: !!chat.admin_rights?.edit_messages
      }
    }
  }

  return sender
}

const _MessageItem: React.FC<MessageProps> = (props) => {
  const { item, users, chats, editMessage, forwardMessage } = props
  const sender = findSender(item.from_id || item.peer_id, users, chats)!
  const fwdFrom = findFwdFromSender(item.fwd_from)
  const medias: Required<ListItem>['media'][] = item.grouped_id
    ? (mediaGroupMap[item.grouped_id] || [])
    : [item.media!]

  // 查找原消息的发送人信息
  function findFwdFromSender (fwdFrom: ListItem['fwd_from']) {
    if (!fwdFrom) return null
    return fwdFrom.from_id ? findSender(fwdFrom.from_id, users, chats) : { title: fwdFrom.from_name }
  }

  // 获取非图片文件的文件名
  function geFileName (media: Required<ListItem>['media']) {
    let fileName = '未知文件'
    media.document.attributes.forEach(item => {
      if (item._ === 'documentAttributeFilename') {
        fileName = item.file_name
      } else {
        fileName = media.document.mime_type
      }
    })
    return fileName
  }

  function VideoPlaceholder () {
    return (
      <div className={scss['video-placeholder']}>
        <VideoCameraOutlined/>
      </div>
    )
  }

  return (
    <div>
      <div className={scss.list__title}>
        <RenderTelegramName value={sender}/>
        {
          fwdFrom &&
          <>
            <span className={scss.list__fwd}>转发自</span>
            <RenderTelegramName value={fwdFrom}/>
          </>
        }
        <div className={scss.list__action}>
          {
            forwardMessage &&
            <a onClick={() => forwardMessage(item.id)}>转发消息</a>
          }
          {
            !fwdFrom &&
            editMessage &&
            (item.out || sender.canEdit) &&
            <>
              <Divider type={'vertical'}/>
              <a onClick={() => editMessage(item)}>编辑消息</a>
            </>
          }
        </div>
      </div>
      <p className={scss.list__date}>
        {dayjs(item.date * 1000).format('YYYY-MM-DD HH:mm:ss')}
      </p>
      <div className={scss.list__content}>
        {
          item.media && item.media._ !== 'messageMediaWebPage' &&
          <div className={scss.list__media}>
            {
              medias.map((item, index) => (
                <div key={index}>
                  {
                    item._ === 'messageMediaPhoto' &&
                    <Image src={require('@/assets/img/placeholder.png')} preview={false}
                    />
                  }
                  {
                    item._ === 'messageMediaDocument' &&
                    (
                      /video/.test(item.document.mime_type)
                        ? <VideoPlaceholder/>
                        : <Tag icon={<PaperClipOutlined/>}>
                          <span>{geFileName(item)}</span>
                        </Tag>
                    )
                  }
                  {
                    item._ === 'messageMediaUnsupported' &&
                    <Tag icon={<PaperClipOutlined/>}>
                      <span>不支持的文件</span>
                    </Tag>
                  }
                </div>
              ))
            }
          </div>
        }
        <span className={scss.list__pre}>
          {/* @ts-ignore */}
          {item.grouped_id ? mediaGroupMap[`m_${item.grouped_id}`] : item.message}
        </span>
      </div>
    </div>
  )
}

export const MessageItem = memo(_MessageItem)

const Index: React.FC = () => {
  const [list, setList] = useState<ListItem[]>([])
  const [open, setOpen] = useState<Record<string, boolean>>({})
  const [record, setRecord] = useState<ListItem>()
  const [target, setTarget] = useState<Target | 'recent'>('recent')
  const [access, setAccess] = useState<AccessItem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({})
  const [pagination, setPagination] = useState<PaginationProps>({
    ...paginationProps,
    pageSize: 50
  })
  const [fwdMessage, setFwdMessage] = useState<FwdMessage>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [sendType, setSendType] = useState<'participant' | 'current'>('current')

  const auths = useAuthsSelector()
  const activeId = useSelector(getActiveId)
  const userList = useMemo(() => {
    const _userId = messages.filter(i => selectedRowKeys.includes(i.id) && i.from_id?.user_id)
      .map(i => i.from_id!.user_id)
    return users.filter(i => _userId.includes(i.id) && !i.self)
      .map(i => ({
        id: i.id,
        phone: i.phone,
        username: i.username,
        first_name: i.first_name,
        last_name: i.last_name,
        access_hash: i.access_hash
      }))
  }, [selectedRowKeys])
  const usernames = useMemo(() => userList.filter(i => i.username).map(i => i.username), [userList])
  const targetList = useMemo(() => userList.map(i => ({ id: i.id, access_hash: i.access_hash })), [userList])
  const sendTarget: Target = useMemo(() => {
    if (sendType === 'participant') {
      return 'peerUser'
    } else {
      return target !== 'peerChannel' ? 'peerUser' : 'peerChannel'
    }
  }, [target, sendType])

  const { current, pageSize } = pagination
  const items: MenuProps['items'] = [
    {
      key: 'sendMessage',
      icon: <MessageOutlined/>,
      label: '发送消息',
      onClick: sendMessage
    },
    {
      key: 'invite',
      icon: <NotificationOutlined/>,
      label: '邀请入群',
      onClick: invite
    },
    {
      key: 'addContacts',
      icon: <PlusOutlined/>,
      label: '添加好友',
      onClick: addContacts
    },
    {
      key: 'collectUsername',
      icon: <ExportOutlined/>,
      label: '采集用户名',
      onClick: collectUsername
    },
    {
      key: 'reload',
      icon: <SyncOutlined/>,
      label: '刷新',
      onClick: reload
    }
  ]
  const columns: ColumnsType<ListItem> = [
    {
      key: 'search',
      title: '消息记录',
      render (_, item) {
        return <MessageItem item={item} editMessage={editMessage} forwardMessage={forwardMessage}/>
      },
      ...useGetColumnSearchProps('search', 'text')
    }
  ]
  const scrollY = useGetTableScrollY(list.length, 56, true)
  const rowSelection: TableProps<ListItem>['rowSelection'] = {
    fixed: true,
    selections: [SELECTION_ALL, SELECTION_NONE],
    columnWidth: 32,
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: setSelectedRowKeys
  }

  useEffect(accessOnchange, [target, access?.id, access?.access_hash])
  // 如果监听 activeId 前一个账号当前账号都会请求一次，
  // 猜测是子组件因为activeId变化，改变channel，父组件的activeId也变化
  // channel和activeId变化未合并未一次更新
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getList, [current, pageSize, filtered.search, access?.id, access?.access_hash])

  // 获取分页数据
  function getList (page?: number) {
    if (!access) return
    const search = formatFilterValue(filtered).search
    const params = {
      _id: activeId,
      type: target === 'peerChannel' ? 'peerChannel' : 'peerUser',
      search,
      peer: {
        id: access.id,
        access_hash: access.access_hash
      },
      page: page || current,
      pageSize
    }
    const apiFn = search ? tg.messagesSearch : tg.getHistory
    setLoading(true)
    apiFn<History>(params)
      .then(({ success, data }) => {
        if (success) {
          const usersId = users.map(i => i.id)
          const messagesId = messages.map(i => i.id)
          const _users = data.users.filter(i => !usersId.includes(i.id))
          const _messages = data.messages.filter(i => !messagesId.includes(i.id))
          chats = data.chats
          users = [...users, ..._users]
          messages = [...messages, ..._messages]
          setList(filterMessages(data.messages))
          setPagination(state => {
            return {
              ...state,
              total: data.count
            }
          })
        }
      })
      .finally(() => setLoading(false))
  }

  // 过滤服务消息，并且合并媒体组
  function filterMessages (messages: ListItem[]) {
    mediaGroupMap = {}
    messages = messages.filter(i => i._ === 'message')
    messages = messages.filter(item => {
      // 不是媒体组消息或者是媒体组消息中的第一条
      const check = !item.grouped_id || !mediaGroupMap[item.grouped_id]
      if (item.grouped_id) {
        if (mediaGroupMap[item.grouped_id]) {
          mediaGroupMap[item.grouped_id].push(item.media!)
        } else {
          mediaGroupMap[item.grouped_id] = [item.media!]
        }
        if (mediaGroupMap[`id_${item.grouped_id}`]) {
          // @ts-ignore
          mediaGroupMap[`id_${item.grouped_id}`].push(item.id)
        } else {
          // @ts-ignore
          mediaGroupMap[`id_${item.grouped_id}`] = [item.id]
        }
        // @ts-ignore
        item.message && (mediaGroupMap[`m_${item.grouped_id}`] = item.message)
      }
      return check
    })
    return messages
  }

  // 目标来源发生变化，清空当前数据
  function accessOnchange () {
    setList([])
    setFiltered({})
    setPagination(s => ({
      ...s,
      total: 0,
      current: 1
    }))

    return () => {
      users = []
      chats = []
      messages = []
      mediaGroupMap = {}
      setSelectedRowKeys([])
    }
  }

  // h5选择下拉框变化，子组件可以调用，所以参数可能为空
  const selectInputOnChange: SelectProps<string, SelectOption>['onChange'] = (value, option) => {
    setAccess(option && {
      id: (option as SelectOption).value,
      access_hash: (option as SelectOption).access_hash
    })
  }

  // 发送消息至群成员
  function sendMessage () {
    if (!targetList.length) return
    setOpen({ send: true })
    setSendType('participant')
  }

  // 邀请入群
  function invite () {
    if (!targetList.length) return
    setOpen({ invite: true })
  }

  // 添加好友
  function addContacts () {
    if (!userList.length) return
    setOpen({ add: true })
  }

  // 采集用户名
  function collectUsername () {
    if (!usernames.length) return
    if (!auths.includes('collect')) return message.info('您当前会员类型不支持采集用户名，如有需要请升级')
    exportRaw(usernames.join('\n'), `${access?.title}_用户采集.txt`)
  }

  // 编辑消息
  function editMessage (item: ListItem) {
    if (item.grouped_id) return message.warning('不支持编辑多个文件的消息')
    setOpen({ edit: true })
    setRecord(item)
  }

  // 转发消息
  function forwardMessage (id?: ListItem['id']) {
    if (!id) return
    if (!auths.includes('forward')) return message.error('当前会员类型不支持转发消息')
    const messages = list.filter(i => i.id === id)
    const peer = messages[0].peer_id
    const sender = findSender(peer)
    const fwdMessage: FwdMessage = {
      id: [],
      from_peer: {
        _: peer._,
        id: sender.id,
        username: sender.username,
        access_hash: sender.access_hash
      }
    }

    if (sender.noforwards) return message.error('该群组已设置禁止转发消息')

    messages.forEach(item => {
      if (item.grouped_id) {
        fwdMessage.id.push(...mediaGroupMap[`id_${item.grouped_id}`] as any)
      } else {
        fwdMessage.id.push(item.id)
      }
    })

    setOpen({ forward: true })
    setFwdMessage(fwdMessage)
  }

  // 发送消息至当前对话
  function sendMessageToCurrent () {
    if (!access) return
    setOpen({ send: true })
    setSendType('current')
  }

  function reload () {
    setPagination(state => {
      if (state.current === 1) getList(1)
      return {
        ...state,
        current: 1
      }
    })
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters) => {
    setFiltered(filters)
    setPagination(state => ({
      ...state,
      ...pagination
    }))
  }

  return (
    <div className="common">
      <Space className="common__header" wrap>
        {
          isMobile
            ? <Space.Compact block>
              <Select
                value={target}
                options={messageTypeOptions}
                onChange={setTarget}/>
              {
                target === 'peerChannel'
                  ? <FormChannelSelect
                    open={true}
                    value={access?.id}
                    style={{ width: 110 }}
                    autoSelectFirst
                    onChange={selectInputOnChange}
                  />
                  : <FormContactSelect
                    open={true}
                    value={access?.id}
                    style={{ width: 110 }}
                    recent={target === 'recent'}
                    autoSelectFirst
                    onChange={selectInputOnChange}
                  />
              }
            </Space.Compact>
            : <Select
              value={target}
              options={messageTypeOptions}
              onChange={setTarget}/>
        }
        {
          isMobile
            ? <Badge count={targetList.length} size={'small'}>
              <Dropdown.Button
                type={'primary'}
                menu={{ items: target === 'peerChannel' ? items : items.slice(-1) }}
                onClick={sendMessageToCurrent}>
                <MessageOutlined/>
                <span>发消息</span>
              </Dropdown.Button>
            </Badge>
            : <>
              <Button
                type="primary"
                icon={<MessageOutlined/>}
                disabled={!access}
                onClick={sendMessageToCurrent}>
                发消息
              </Button>
              {
                target === 'peerChannel' &&
                <>
                  <Badge count={targetList.length} size={'small'} overflowCount={999}>
                    <Button type="primary" icon={<MessageOutlined/>} onClick={sendMessage}>
                      发送消息
                    </Button>
                  </Badge>
                  <Badge count={targetList.length} size={'small'} overflowCount={999}>
                    <Button type="primary" icon={<NotificationOutlined/>} onClick={invite}>
                      邀请入群
                    </Button>
                  </Badge>
                  <Badge count={userList.length} size={'small'} overflowCount={999}>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={addContacts}>
                      添加好友
                    </Button>
                  </Badge>
                  <Badge count={usernames.length} size={'small'} overflowCount={999}>
                    <Button icon={<ExportOutlined/>} onClick={collectUsername}>
                      采集用户名
                    </Button>
                  </Badge>
                </>
              }
              <Button disabled={loading} onClick={reload}>
                刷新
              </Button>
            </>
        }
      </Space>
      <Row wrap={false}>
        {
          !isMobile &&
          <Col flex="260px">
            {
              target === 'peerChannel'
                ? <ChannelSelectTable onChange={setAccess}/>
                : <ContactSelectTable recent={target === 'recent'} onChange={setAccess}/>
            }
          </Col>
        }
        <Col flex="auto">
          <Table
            rowKey="id"
            sticky
            scroll={{
              x: '100%',
              y: scrollY,
              scrollToFirstRowOnChange: true
            }}
            loading={loading}
            columns={columns}
            dataSource={list}
            className={scss.list}
            pagination={pagination}
            rowSelection={rowSelection}
            rowClassName={record => record.out ? scss.bg : ''}
            onChange={onChange}
          />
        </Col>
      </Row>
      <SendMessage
        open={open.send}
        target={sendTarget}
        targetList={sendType === 'participant' ? targetList : [access!]}
        onSuccess={getList}
        onClose={() => setOpen({})}
      />
      <EditMessage
        open={open.edit}
        record={record!}
        onSuccess={getList}
        onClose={() => setOpen({})}
      />
      <ForwardMessage
        open={open.forward}
        fwdMessage={fwdMessage!}
        onClose={() => setOpen({})}
      />
      <AddContact
        open={open.add}
        targetList={userList}
        onCancel={() => setOpen({})}
      />
      <Invite
        open={open.invite}
        targetList={targetList}
        onCancel={() => setOpen({})}
      />
    </div>
  )
}

export default Index
