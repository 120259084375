import React, { memo } from 'react'
import { Form, Input, Divider, InputNumber, Popconfirm, Select, SelectProps, Space } from 'antd'
import { getOptions, Options } from '@/store/slice/account'
import { useSelector } from 'react-redux'
import { requiredSelectRule } from '@/utils/hooks'
import { FormInstance } from 'antd/lib'
import { useForm } from 'antd/es/form/Form'
import { getTaskAccount } from '@/views/task/api'

interface Props {
  form: FormInstance
  extra?: React.ReactNode
  hidden?: boolean
  showNoTask?: boolean
}

const Index: React.FC<Props> = (props) => {
  const { form, extra, hidden, showNoTask } = props

  const [_form] = useForm()
  const options = useSelector(getOptions)

  // 选择全部账号
  function selectAll () {
    form.setFieldValue('_id', options.map(i => i.value))
    form.validateFields(['_id'])
  }

  // 选择没有任务的账号
  function selectNoTask () {
    getTaskAccount().then(({ data, success }) => {
      if (success) {
        const id = options.filter(i => !data.includes(i.value)).map(i => i.value)
        form.setFieldValue('_id', id)
      }
    })
  }

  // 自定义数量账号
  function custom (values: Record<string, any>) {
    let { min = 0, max } = values
    if (!min && !max) return
    min = min === 1 ? 0 : min
    form.setFieldValue('_id', options.slice(min, max || undefined).map(i => i.value))
  }

  const filterOption: SelectProps<string, Options>['filterOption'] = (inputValue, option) => {
    return option!.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      new RegExp(inputValue).test(option!.phone)
  }

  return hidden
    ? <Form.Item name={'_id'} hidden>
      <Input type={'hidden'}/>
    </Form.Item>
    : <Form.Item
      name="_id"
      label={
        <Space size={0} split={<Divider type="vertical"/>}>
          <span>账号</span>
          <a onClick={selectAll}>全选</a>
          <Popconfirm
            title={`共${options.length}个`}
            description={
              <Form form={_form} style={{ width: 200 }} onFinish={custom}>
                <Space.Compact block>
                  <Form.Item name={'min'} noStyle>
                    <InputNumber
                      min={1}
                      max={options.length}
                      style={{ flex: 'auto' }}
                      pattern="\d*"
                      precision={0}
                      placeholder={'1'}/>
                  </Form.Item>
                  <Form.Item noStyle>
                    <Input
                      style={{
                        flex: '0 0 30px',
                        padding: '0',
                        textAlign: 'center',
                        pointerEvents: 'none'
                      }}
                      placeholder="~"/>
                  </Form.Item>
                  <Form.Item name={'max'} noStyle>
                    <InputNumber
                      min={2}
                      max={options.length}
                      style={{ flex: 'auto' }}
                      pattern="\d*"
                      precision={0}
                      placeholder={String(options.length)}/>
                  </Form.Item>
                </Space.Compact>
              </Form>
            }
            onConfirm={_form.submit}>
            <a>自选数量</a>
          </Popconfirm>
          {
            showNoTask &&
            <a onClick={selectNoTask}>无任务账号</a>
          }
        </Space>
      }
      rules={requiredSelectRule}
      extra={extra}>
      <Select
        mode={'multiple'}
        showSearch
        maxTagCount={1}
        placeholder={'请选择账号'}
        filterOption={filterOption}>
        {
          options.map(item => (
            <Select.Option key={item.value} phone={item.phone} value={item.value} label={item.label}>
              <div>
                <span>{item.label}&nbsp;</span>
                <span style={{ fontSize: 12, opacity: 0.5 }}>
                  {item.chatCount}群组&nbsp;
                  {item.contactCount}好友
                </span>
              </div>
            </Select.Option>
          ))
        }
      </Select>
    </Form.Item>
}

export default memo(Index)
