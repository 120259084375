import http from '@/api/http'
import { RequestParams, ResponseData } from '@/api/types'
import { AxiosRequestConfig } from 'axios'

// 获取用户列表
export function list<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/list'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 添加用户
export function create<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/create'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新用户
export function update<T = any> (params: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/update'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新用户启用禁用
export function updateEnable<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/updateEnable'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 重置密码
export function resetPassword<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/resetPassword'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新密码（使用旧密码）
export function updatePassword<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/updatePassword'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 删除用户
export function remove<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/remove'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 更新密码（使用邮箱）
export function retrievePassword<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/retrievePassword'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 通过用户名查找id
export function getIdByKeyValue<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/getIdByKeyValue'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 用户名是否存在
export function userExists<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/userExists'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取没有接单人的用户
export function getUserNoSeller<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/getUserNoSeller'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 解除飞机号绑定
export function unboundTelegram<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/unboundTelegram'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}

// 获取过期会员的用户名
export function getExpiredUsername<T = any> (params?: RequestParams, config?: AxiosRequestConfig) {
  const url = '/user/getExpiredUsername'
  return http.post<ResponseData<T>>(url, params, config).then(r => r.data)
}
