import React, { memo, useState } from 'react'
import { Modal, Form, message } from 'antd'
import { isMobile } from '@/utils/hooks'
import { DelayFormItem } from '@/views/telegram/SendMessage'
import { useSelector } from 'react-redux'
import { getActiveId } from '@/store/slice/account'
import { UserParams } from './index'
import * as tg from '@/views/telegram/api'
import * as taskApi from '@/views/task/api'

interface Props {
  open: boolean
  targetList: UserParams[]
  onCancel: () => void
}

interface FormValues {
  delay: string
  delayUnit: string
}

const Index: React.FC<Props> = (props) => {
  const { open = false, targetList, onCancel } = props
  const [loading, setLoading] = useState<boolean>(false)

  const activeId = useSelector(getActiveId)

  const [form] = Form.useForm()

  function onFinish (values: FormValues) {
    const params = {
      ...values,
      _id: activeId,
      targetList: targetList.map(item => ({
        ...item,
        first_name: encodeURIComponent(item.first_name),
        last_name: item.last_name && encodeURIComponent(item.last_name)
      }))
    }

    setLoading(true)

    if (targetList.length === 1) {
      online()
    } else {
      task()
    }

    function task () {
      const task = {
        action: 'addContacts',
        params
      }
      return taskApi.create(task).then(({ success }) => {
        if (success) {
          onCancel()
          message.success('任务添加成功')
        }
      })
    }

    function online () {
      return tg.addContacts<string[]>(params)
        .then(({ success, data }) => {
          if (success) {
            onCancel()
            message.success(`成功添加 ${data.filter(i => i === 'success').length} 人`)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Modal
      open={open}
      title={`添加好友 - ${targetList.length}个目标`}
      centered={isMobile}
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      afterClose={form.resetFields}
      onOk={form.submit}
      onCancel={onCancel}>
      <Form
        name="add"
        form={form}
        layout="vertical"
        initialValues={{ delay: 3, delayUnit: 'seconds' }}
        onFinish={onFinish}>
        <DelayFormItem
          min={1}
          tooltip={'目标数量过多时请提高单个间隔'}
          timeUnitList={['seconds']}/>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
