import React, { useState, memo, useEffect } from 'react'
import { Image } from 'antd'
import { ImagePreviewType } from 'rc-image/lib/Image'

interface Props {
  url?: string
  setUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Index: React.FC<Props> = (props) => {
  const { url, setUrl } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => setVisible(!!url), [url])

  const onVisibleChange: ImagePreviewType['onVisibleChange'] = (value) => {
    setVisible(value)
    if (!value) {
      setUrl(undefined)
      URL.revokeObjectURL(url!)
    }
  }

  return (
    <Image
      width={200}
      style={{ display: 'none' }}
      preview={{
        src: url,
        visible,
        onVisibleChange
      }}
    />
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.url === nextProps.url
})
