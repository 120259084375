import React, { memo, useEffect, useMemo, useState } from 'react'
import { Modal, Form, Input, InputNumber, message, Select, Row, Col, Button, Space } from 'antd'
import { addressRule, isMobile, requiredInputRule, requiredSelectRule, useUserSelector } from '@/utils/hooks'
import { ListItem as Payment } from '@/views/payments'
import { Rule } from 'antd/es/form'
import { SafetyCertificateOutlined } from '@ant-design/icons'
import { getEmailCode } from '@/views/user/api'
import { getBalance } from '@/store/slice/user'
import { RootState, useAppDispatch } from '@/store'
import { useSelector } from 'react-redux'
import { getPaymentList, getPaymentsByCode } from '@/store/slice/payment'
import * as api from './api'

interface Props {
  open: boolean
  type: 'outflow' | 'depositOutflow'
  onCancel: () => void
}

const paymentMap = {
  outflow: 'inflow',
  depositOutflow: 'depositInflow'
}

const Index: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const { open = false, type, onCancel } = props
  const [count, setCount] = useState<number>(0)
  const [amount, setAmount] = useState<null | number>(0)
  const [loading, setLoading] = useState<Record<string, boolean>>({})
  const [paymentId, setPaymentId] = useState<Payment['_id']>()

  const user = useUserSelector()
  const payments = useSelector(state => getPaymentsByCode(state as RootState, paymentMap[type]))
  const payment = useMemo(() => payments.find(i => i._id === paymentId), [payments, paymentId])
  const paymentAmount = useMemo(() => amount && payment?.rate && amount * payment.rate, [amount, payment])

  const [form] = Form.useForm()
  const captchaRule: Rule[] = [
    ...requiredInputRule,
    {
      validator () {
        if (!form.getFieldValue('captchaId')) {
          return Promise.reject(Error('请先获取验证码'))
        }
        return Promise.resolve()
      }
    }
  ]

  useEffect(openToggle, [dispatch, form, open, user])
  useEffect(countdown, [count])

  function openToggle () {
    if (open) {
      const amount = user.balance
      const receiptAccount = localStorage.getItem('receiptAccount')
      form.setFieldValue('amount', amount)
      form.setFieldValue('receiptAccount', receiptAccount)
      setAmount(amount)
      dispatch(getPaymentList())
    }
  }

  function resetForm () {
    form.resetFields()
    setAmount(0)
    setPaymentId(undefined)
  }

  // 获取邮箱验证码
  function getCaptcha () {
    const params = {
      email: user.email
    }

    if (!user.email) return message.error('请绑定邮箱')

    setLoading(s => ({ ...s, captcha: true }))
    getEmailCode(params)
      .then(({ success, data }) => {
        if (success) {
          setCount(60)
          form.setFieldsValue({ captchaId: data.captchaId })
          message.success(`验证码已发送到邮箱，${data.exp}分钟内有效，请注意查收`)
        }
      })
      .finally(() => setLoading(s => ({ ...s, captcha: false })))
  }

  // 获取验证码倒计时
  function countdown () {
    let timer: NodeJS.Timeout
    if (count <= 0) {
      setCount(0)
    } else {
      timer = setTimeout(() => setCount(count - 1), 1000)
    }

    return () => clearTimeout(timer)
  }

  function onFinish (values: Record<string, any>) {
    values = {
      ...values,
      type,
      payment: {
        ...payment,
        receiptAccount: values.receiptAccount
      }
    }
    setLoading({ submit: true })
    api.create(values).then(({ success, message: msg }) => {
      if (success) {
        onCancel()
        dispatch(getBalance())
        message.success(msg)
        localStorage.setItem('receiptAccount', values.receiptAccount)
      }
    }).finally(() => setLoading({}))
  }

  return (
    <Modal
      open={open}
      title={type === 'outflow' ? '提现' : '取出押金'}
      centered={isMobile}
      forceRender
      className="common-modal"
      maskClosable={false}
      confirmLoading={loading.submit}
      afterClose={resetForm}
      onOk={() => form.submit()}
      onCancel={onCancel}>
      <Form
        name="outflow"
        form={form}
        layout="vertical"
        initialValues={{ email: user.email }}
        onFinish={onFinish}>
        <Form.Item name="email" noStyle>
          <Input hidden/>
        </Form.Item>
        <Form.Item name="captchaId" noStyle>
          <Input hidden/>
        </Form.Item>
        <Form.Item name="payment" label="支付方式" rules={requiredSelectRule}>
          <Select
            options={payments}
            placeholder="请选择"
            fieldNames={{ label: 'name', value: '_id' }}
            onChange={setPaymentId}/>
        </Form.Item>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="amount" label="提现金额" rules={requiredInputRule}>
              <InputNumber
                pattern="\d*"
                min={1}
                max={user.balance}
                disabled={type === 'depositOutflow'}
                precision={2}
                placeholder="请输入"
                onChange={setAmount}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="到账金额" rules={requiredInputRule}>
              <InputNumber
                value={paymentAmount}
                readOnly
                precision={0}
                addonBefore={payment?.currency}/>
            </Form.Item>
          </Col>
        </Row>
        {
          payment &&
          (
            payment.type === 'usdt'
              ? <Form.Item
                name={'receiptAccount'}
                label={'收款钱包'}
                rules={addressRule}>
                <Input allowClear placeholder={'请输入收款钱包，请注意核对区块链协议类型'}/>
              </Form.Item>
              : <Form.Item
                name={'receiptAccount'}
                label={'收款账号'}
                rules={requiredInputRule}>
                <Input allowClear placeholder={'请输入'}/>
              </Form.Item>
          )
        }
        <Form.Item required label={'验证码'}>
          <Space.Compact block>
            <Form.Item name="captcha" dependencies={['captchaId']} rules={captchaRule} noStyle validateFirst>
              <Input
                style={{ width: 'calc(100% - 120px)' }}
                prefix={<SafetyCertificateOutlined/>}
                maxLength={6}
                pattern="\d*"
                placeholder="邮箱验证码"/>
            </Form.Item>
            <Button
              style={{ width: 120 }}
              onClick={getCaptcha}
              loading={loading.captcha}
              disabled={count > 0}>
              {
                count > 0
                  ? count + ' s'
                  : '发送验证码'
              }
            </Button>
          </Space.Compact>
        </Form.Item>
        <Form.Item name="remarks" label="备注">
          <Input.TextArea rows={3} maxLength={100} showCount placeholder="请输入"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
