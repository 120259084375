import React, { Key, ReactNode, useEffect, useMemo, useState } from 'react'
import moment from 'dayjs'
import {
  Badge,
  Button,
  Descriptions,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip
} from 'antd'
import { PaginationProps } from 'antd/es/pagination'
import { ColumnsType, TableProps } from 'antd/es/table'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LinkOutlined,
  MessageOutlined,
  MinusCircleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined
} from '@ant-design/icons'
import { formatFilterValue } from '@/utils'
import {
  FilterValueState,
  isMobile,
  paginationProps,
  renderActions,
  RenderTelegramName,
  SortOrderState,
  TColumnsType,
  useAuthsSelector,
  useGetColumnSearchProps,
  useGetTableScrollX,
  useGetTableScrollY
} from '@/utils/hooks'
import { ColumnFilterItem } from 'antd/es/table/interface'
import { ListItem as Telegram } from '@/views/telegram/account'
import { File, Target } from '@/views/telegram/types'
import { FwdMessage } from '@/views/telegram/message'
import { TagProps } from 'antd/es/tag'
import Logs from '@/views/task/logs'
import SendMessage, { timeUnit } from '@/views/telegram/SendMessage'
import ForwardMessage from '@/views/telegram/ForwardMessage'
import SendMessageByUsername from '@/views/telegram/SendMessageByUsername'
import * as api from './api'
import { translate } from '@/utils/translate'

export const actionList = [
  {
    text: '添加好友',
    value: 'addContacts'
  },
  {
    text: '添加群组',
    value: 'joinChannels'
  },
  {
    text: '发送消息',
    value: 'sendMessage'
  },
  {
    text: '转发消息',
    value: 'forwardMessages'
  },
  {
    text: '批量私聊',
    value: 'sendMessageByUsername'
  },
  // {
  //   text: '邀请入群',
  //   value: 'inviteToChannel'
  // },
  {
    text: '发送图片消息',
    value: 'sendMedia'
  },
  {
    text: '发送多图片消息',
    value: 'sendMultiMedia'
  },
  {
    text: '用户名添加好友',
    value: 'addContactsByUsername'
  },
  {
    text: '群链接添加群组',
    value: 'joinChannelsByUsername'
  },
  {
    text: '邀请链接添加群组',
    value: 'importChatInvite'
  }
] as const

type Action = typeof actionList[number]['value']

export interface ListItem {
  _id: string,
  account: Telegram
  username: string
  status: 'waiting' | 'processing' | 'stopped' | 'success' | 'error' // 任务状态
  params: {
    _id: string
    loop?: boolean // 循环 发/转发消息
    time?: Date[] // 发消息类，运行时间段
    delay: number
    maxDelay?: number
    // photo?: SavePhoto[] // 图片 发送消息
    photo?: File[] // 图片 发送消息
    target?: Target // 目标类型 发送消息/转发消息
    footer?: 'emoji' | 'letter' | 'nothing' // 随机消息尾部 发送消息
    message?: string // 发送消息
    interval?: number // 循环间隔 发消息类
    maxInterval?: number // 循环间隔 发消息类
    execType?: 'task' | 'online' // 执行方式 发/转发消息
    stopDate?: string // 停止时间
    delayUnit: 'seconds' | 'minute'
    targetList: any[] // 目标列表
    fwdMessage?: FwdMessage // 转发消息
    dropAuthor?: boolean // 转发消息
    previewLink?: boolean // 链接预览 发送消息
    intervalUnit?: 'seconds' | 'minute' | 'hour' | 'day' // 发/转发消息
  } // 执行参数
  dates: Record<string, string> // 执行结果
  result: Record<string, string> // 执行结果
  action: Action // 对应的TG执行方法
  looping: boolean // 任务是否在执行中
  remarks?: string // 备注
  loopedCount: number // 已循环次数
  nextExecTime: number // 下次执行时间
  expiredDate?: Date // 过期日期 TTL索引字段，不设置值不会被删除
  createdAt: string
  updatedAt: string
}

const statusMap: Record<ListItem['status'], { text: string, color: TagProps['color'], icon?: ReactNode }> = {
  waiting: {
    icon: <ClockCircleOutlined/>,
    text: '待运行',
    color: 'default'
  },
  processing: {
    icon: <SyncOutlined spin/>,
    text: '运行中',
    color: 'processing'
  },
  success: {
    icon: <CheckCircleOutlined/>,
    text: '已完成',
    color: 'success'
  },
  stopped: {
    icon: <MinusCircleOutlined/>,
    text: '已暂停',
    color: 'warning'
  },
  error: {
    icon: <CloseCircleOutlined/>,
    text: '运行错误',
    color: 'error'
  }
}
const addActions: ListItem['action'][] = ['addContacts', 'joinChannels', 'importChatInvite', 'addContactsByUsername', 'joinChannelsByUsername']
const sendActions: ListItem['action'][] = ['sendMessage', 'forwardMessages', 'sendMedia', 'sendMultiMedia', 'sendMessageByUsername']

const Index: React.FC = () => {
  const [list, setList] = useState<ListItem[]>([])
  const [open, setOpen] = useState<Record<string, boolean>>({})
  const [record, setRecord] = useState<ListItem>()
  const [loading, setLoading] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<FilterValueState>({})
  const [sortOrder, setSortOrder] = useState<SortOrderState>({ createdAt: 'descend' })
  const [pagination, setPagination] = useState<PaginationProps>(paginationProps)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

  const auths = useAuthsSelector()
  const usernameSearch = useGetColumnSearchProps('username')
  const [canStopIds, canStartIds, canUpdateIds] = useMemo(getFilteredIds, [list, selectedRowKeys])

  const { current, pageSize } = pagination
  const items: MenuProps['items'] = [
    // {
    //   key: 'updates',
    //   icon: <EditOutlined/>,
    //   label: '批量编辑',
    //   onClick: updates
    // },
    {
      key: 'forwardMessage',
      icon: <LinkOutlined/>,
      label: '批量转发',
      onClick: forwardMessage
    },
    {
      key: 'sendMessageByUsername',
      icon: <MessageOutlined/>,
      label: '批量私聊',
      onClick: sendMessageByUsername
    },
    {
      key: 'startTasks',
      icon: <PlayCircleOutlined/>,
      label: '启动',
      onClick: startTasks
    },
    {
      key: 'stopTasks',
      icon: <PauseCircleOutlined/>,
      label: '暂停',
      onClick: stopTasks
    },
    {
      key: 'removes',
      icon: <DeleteOutlined/>,
      label: <Popconfirm
        title="你确定要删除选中记录吗？"
        onConfirm={() => removes()}>
        删除
      </Popconfirm>,
      danger: true
    }
  ]
  const _columns: TColumnsType<ListItem> = [
    auths.includes('usernameColumn') &&
    {
      title: '用户',
      width: 120,
      ellipsis: true,
      dataIndex: 'username',
      filteredValue: filtered.username || null,
      ...usernameSearch
    },
    {
      title: '账号',
      width: 160,
      ellipsis: { showTitle: false },
      dataIndex: 'account',
      filteredValue: filtered.account || null,
      render (value) {
        return value && <RenderTelegramName value={value.user}/>
      },
      ...useGetColumnSearchProps('account', 'text')
    },
    {
      title: '任务名称',
      width: 160,
      dataIndex: 'action',
      filters: actionList as unknown as ColumnFilterItem[],
      filteredValue: filtered.action || null,
      filterMultiple: false,
      render (value, record) {
        const action = actionList.find(item => item.value === value)?.text
        const target = record.params.target === 'peerUser' ? '至用户' : '至群组'
        return action + (record.params.target ? target : '')
      }
    },
    {
      title: '任务状态',
      width: 100,
      dataIndex: 'status',
      filters: Object.keys(statusMap).map(key => {
        return {
          text: statusMap[key as ListItem['status']].text,
          value: key
        }
      }),
      filteredValue: filtered.status || null,
      // filterMultiple: false,
      render (value: ListItem['status'], record) {
        const tag = <Tag icon={statusMap[value].icon} color={statusMap[value].color}>{statusMap[value].text}</Tag>
        if (record.remarks) {
          return <Tooltip title={translate(record.remarks)}>{tag}</Tooltip>
        }
        return tag
      }
    },
    {
      title: '任务类型',
      width: 100,
      key: 'params.loop',
      dataIndex: 'params',
      filters: [
        {
          text: '循环',
          value: true
        },
        {
          text: '单次',
          value: false
        }
      ],
      filteredValue: filtered['params.loop'] || null,
      filterMultiple: false,
      render (value: ListItem['params']) {
        return value.loop
          ? <Tag color="blue">循环</Tag>
          : <Tag color="default">单次</Tag>
      }
    },
    {
      title: '目标数量',
      width: 80,
      dataIndex: 'params',
      render (value) {
        return value.targetList?.length
      }
    },
    {
      title: '成功数量',
      width: 80,
      dataIndex: 'result',
      render (value: ListItem['result']) {
        const result = Object.values(value || {})
        return result.filter(i => i === 'success' || /^\d+$/.test(i)).length
      }
    },
    {
      title: '单个间隔',
      width: 90,
      dataIndex: 'params',
      render (value: ListItem['params']) {
        const delay = [value.delay, value.maxDelay].join('-').replace(/-$/, '')
        return `${delay} ${timeUnit.find(i => i.value === value.delayUnit)?.label}`
      }
    },
    {
      title: '预计耗时',
      width: 80,
      dataIndex: 'params',
      render (value: ListItem['params']) {
        const { delay, maxDelay, delayUnit, targetList } = value
        const _delay = maxDelay ? (delay + maxDelay) / 2 : delay
        const duration = moment.duration(_delay * targetList.length, delayUnit)
        const hours = Math.floor(duration.asHours())
        return (hours > 9 ? '' : '0') + hours + duration.format(':mm:ss')
      }
    },
    {
      title: '循环间隔',
      width: 90,
      dataIndex: 'params',
      render (value: ListItem['params']) {
        if (!value.loop) return '-'
        const interval = [value.interval, value.maxInterval].join('-').replace(/-$/, '')
        return `${interval} ${timeUnit.find(i => i.value === value.intervalUnit)?.label}`
      }
    },
    {
      title: '已循环',
      width: 80,
      dataIndex: 'loopedCount',
      render (value, record) {
        return value ? <a onClick={() => toDetails(record)}>{value}</a> : '-'
      }
    },
    auths.includes('viewServer') &&
    {
      key: 'proxyId',
      title: '服务器',
      width: 80,
      dataIndex: 'account',
      render (value: ListItem['account']) {
        return <span>{value?.proxyId?.toUpperCase()}｜{value.serverId}</span>
      }
    },
    {
      title: '创建时间',
      width: 166,
      sorter: true,
      sortOrder: sortOrder.createdAt,
      dataIndex: 'createdAt',
      render (value, record) {
        return <Tooltip title={moment(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
          {moment(value).format('YYYY-MM-DD HH:mm:ss')}
        </Tooltip>
      }
    },
    {
      title: '操作',
      width: 140,
      fixed: 'right',
      dataIndex: 'params',
      render (value: ListItem['params'], record) {
        const actions: ReactNode[] = [
          // 单次任务，在发一次
          !value.loop &&
          record.status === 'success' &&
          sendActions.includes(record.action) &&
          <a onClick={() => sendAgain(record)}>
            再发
          </a>,
          // 单次任务，在加一次
          !value.loop &&
          record.status === 'success' &&
          addActions.includes(record.action) &&
          <a onClick={() => addAgain(record)}>
            再加
          </a>,
          // 循环任务，启动/暂停
          value.loop &&
          record.status !== 'error' &&
          <>
            {
              record.status === 'stopped' &&
              <a onClick={() => updateStatus(record, 'waiting')}>
                启动
              </a>
            }
            {
              record.status === 'waiting' &&
              <a onClick={() => updateStatus(record, 'stopped')}>
                暂停
              </a>
            }
            {
              record.status === 'processing' &&
              <a onClick={() => stopProcessing(record)}>
                暂停
              </a>
            }
          </>,
          // 发送消息任务，编辑
          (value.loop || (sendActions.includes(record.action) && record.status === 'success')) &&
          <a onClick={() => update(record)}>编辑</a>,
          <a key={'openDetailsDialog'} onClick={() => openDetailsDialog(record)}>详情</a>
        ]
        return renderActions(actions)
      }
    }
  ]
  const columns = _columns.filter(Boolean) as ColumnsType<ListItem>
  const expandable: TableProps<ListItem>['expandable'] = {
    columnWidth: 32,
    expandedRowRender
  }
  const rowSelection: TableProps<ListItem>['rowSelection'] = {
    fixed: true,
    columnWidth: 32,
    selectedRowKeys,
    onChange: setSelectedRowKeys
  }

  const scrollX = useGetTableScrollX(columns, 64)
  const scrollY = useGetTableScrollY(list.length)

  useEffect(getList, [current, pageSize, filtered, sortOrder])

  function getList () {
    const params = {
      sort: sortOrder,
      page: current,
      pageSize,
      ...formatFilterValue(filtered)
    }
    setLoading(true)
    api.list(params).then(({ success, data }) => {
      if (success) {
        setList(data.rows)
        setPagination(state => ({
          ...state,
          total: data.total,
          current: data.page,
          pageSize: data.pageSize
        }))
      }
    }).finally(() => setLoading(false))
  }

  function update (record: ListItem) {
    setRecord(record)
    if (record.action === 'forwardMessages') {
      setOpen({ forward: true })
    } else {
      setOpen({ send: true })
    }
  }

  // function updates () {
  //   if (!canUpdateIds.length) return
  //   setRecord(undefined)
  // }

  // 更新任务状态
  function updateStatus (record: ListItem, status: ListItem['status']) {
    const params = {
      _id: record._id,
      status
    }
    setLoading(true)
    return api.updateStatus(params).then(({ data, success }) => {
      if (success) updateSuccess(data, record)
    }).finally(() => setLoading(false))
  }

  // 批量更新任务状态
  function batchUpdateStatus (ids: ListItem['_id'][], status: ListItem['status']) {
    const params = {
      _id: ids,
      status
    }
    setLoading(true)
    api.batchUpdateStatus(params).then(({ data, success }) => {
      if (success) return getList()
    }).finally(() => setLoading(false))
  }

  // 批量启动任务
  function startTasks () {
    if (!canStartIds.length) return
    batchUpdateStatus(canStartIds, 'waiting')
  }

  // 批量暂停任务
  function stopTasks () {
    if (!canStopIds.length) return
    batchUpdateStatus(canStopIds, 'stopped')
  }

  // 再次添加
  function addAgain (record: ListItem) {
    const { result, params } = record
    const _params: Partial<ListItem> = {
      _id: record._id,
      dates: {},
      result: {},
      status: 'waiting',
      params: {
        ...params,
        targetList: params.targetList.filter((_, index) => {
          return ['Timeout', 'Offline'].includes(result[index]) || /^FLOOD_WAIT_/.test(result[index])
        })
      }
    }
    setLoading(true)
    api.update(_params).then(({ data, success }) => {
      if (success) updateSuccess(data, record)
    }).finally(() => setLoading(false))
  }

  // 再次发送
  function sendAgain (record: ListItem) {
    const _params = {
      _id: record._id,
      dates: {},
      result: {},
      status: 'waiting'
    }
    setLoading(true)
    api.update(_params).then(({ data, success }) => {
      if (success) updateSuccess(data, record)
    }).finally(() => setLoading(false))
  }

  // 暂停运行中的任务
  function stopProcessing (record: ListItem) {
    Modal.confirm({
      title: '暂停任务',
      content: '如需编辑任务，可直接编辑，将在下一轮运行中生效。当前任务正在运行中，请确认是否暂停？',
      centered: true,
      onOk: () => updateStatus(record, 'stopped')
    })
  }

  function updateSuccess (data: ListItem, _record = record) {
    if (_record && data) {
      const { account, ...update } = data
      Object.assign(_record, update)
      setList(JSON.parse(JSON.stringify(list)))
    } else {
      getList()
    }
  }

  function removes () {
    if (!selectedRowKeys.length) return
    const params = {
      _id: selectedRowKeys
    }

    return api.removes(params).then(({ success }) => {
      if (success) {
        getList()
        setSelectedRowKeys([])
      }
    })
  }

  function openDetailsDialog (record: ListItem) {
    setRecord(record)
    setOpen({ logs: true })
  }

  function toDetails (record: ListItem) {
    localStorage.setItem('task', JSON.stringify(record))
    window.open('/taskRecord', 'taskRecord')
  }

  function sendMessage () {
    setOpen({ send: true })
    setRecord(undefined)
  }

  function forwardMessage () {
    if (!auths.includes('forward')) return message.info('当前会员类型不支持转发消息')
    setOpen({ forward: true })
    setRecord(undefined)
  }

  function sendMessageByUsername () {
    setOpen({ sendBy: true })
    setRecord(undefined)
  }

  // 将选中的id筛选出不同类型
  function getFilteredIds () {
    const _list = list.filter(i => i.params.loop && selectedRowKeys.includes(i._id))
    const canStopIds = _list.filter(i => ['waiting', 'processing'].includes(i.status)).map(i => i._id)
    const canStartIds = _list.filter(i => i.status === 'stopped').map(i => i._id)
    const canUpdateIds = _list.map(i => i._id)

    return [canStopIds, canStartIds, canUpdateIds]
  }

  function resetFilter () {
    setFiltered({})
  }

  function expandedRowRender (record: ListItem) {
    const { params, result, remarks, nextExecTime } = record
    const { delay, maxDelay, delayUnit, targetList } = params
    const _delay = maxDelay ? (delay + maxDelay) / 2 : delay
    return (
      <Descriptions size={'small'} column={1} bordered labelStyle={{
        width: 100,
        textAlign: 'center'
      }}>
        {
          auths.includes('showParams') &&
          <Descriptions.Item label="任务参数">
            <pre>{JSON.stringify(params)}</pre>
          </Descriptions.Item>
        }
        {
          auths.includes('showParams') && result &&
          <Descriptions.Item label="任务结果">
            <pre>{JSON.stringify(result)}</pre>
          </Descriptions.Item>
        }
        <Descriptions.Item label="开始时间">
          <span>{moment(nextExecTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        </Descriptions.Item>
        <Descriptions.Item label="预计结束">
          <span>
            {moment(nextExecTime).add(_delay * targetList.length, delayUnit).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="运行备注">
          <span>{translate(remarks) || '-'}</span>
        </Descriptions.Item>
      </Descriptions>
    )
  }

  const onChange: TableProps<ListItem>['onChange'] = (pagination, filters, sorter) => {
    sorter = Array.isArray(sorter) ? sorter[0] : sorter
    const sort = {
      [String(sorter.field)]: sorter.order
    }
    setSortOrder(sort)
    setFiltered(filters)
    setPagination(prevState => ({
      ...prevState,
      ...pagination
    }))
  }

  return (
    <div className="common">
      <Space className="common__header" wrap>
        {
          isMobile
            ? <Badge count={canUpdateIds.length} size={'small'}>
              <Dropdown.Button menu={{ items }} type={'primary'} onClick={sendMessage}>
                <MessageOutlined/>
                <span>批量发送</span>
              </Dropdown.Button>
            </Badge>
            : <>
              <Button type="primary" icon={<MessageOutlined/>} onClick={sendMessage}>
                批量发送
              </Button>
              <Button type="primary" icon={<LinkOutlined/>} onClick={forwardMessage}>
                批量转发
              </Button>
              <Button type="primary" icon={<MessageOutlined/>} onClick={sendMessageByUsername}>
                批量私聊
              </Button>
              {/* <Badge count={canUpdateIds.length} size={'small'}>
               <Button type="primary" icon={<EditOutlined/>} onClick={updates}>
               批量编辑
               </Button>
               </Badge> */}
              <Badge count={canStartIds.length} size={'small'}>
                <Button type="primary" icon={<PlayCircleOutlined/>} onClick={startTasks}>
                  启动
                </Button>
              </Badge>
              <Badge count={canStopIds.length} size={'small'}>
                <Button type="primary" icon={<PauseCircleOutlined/>} onClick={stopTasks}>
                  暂停
                </Button>
              </Badge>
              <Popconfirm
                title="你确定要删除选中记录吗？"
                onConfirm={removes}>
                <Badge count={selectedRowKeys.length} size={'small'}>
                  <Button icon={<DeleteOutlined/>} type="dashed" danger>
                    删除
                  </Button>
                </Badge>
              </Popconfirm>
            </>
        }
        <Button onClick={resetFilter} disabled={loading}>
          重置筛选
        </Button>
        <Tooltip title={'非循环任务，任务结束后，任务记录只保存3天'}>
          <Button type={'text'} icon={<QuestionCircleOutlined/>}/>
        </Tooltip>
      </Space>
      <Table
        rowKey="_id"
        scroll={{
          x: scrollX,
          y: scrollY
        }}
        loading={loading}
        columns={columns}
        dataSource={list}
        pagination={pagination}
        expandable={expandable}
        rowSelection={rowSelection}
        onChange={onChange}/>
      <SendMessage
        open={open.send}
        record={record}
        multiAccount={!record}
        canChangeTargetList
        onSuccess={getList}
        updateSuccess={updateSuccess}
        onClose={() => setOpen({})}
      />
      <ForwardMessage
        open={open.forward}
        record={record}
        multiAccount={!record}
        onSuccess={getList}
        updateSuccess={updateSuccess}
        onClose={() => setOpen({})}
      />
      <SendMessageByUsername
        open={open.sendBy}
        onSuccess={getList}
        onClose={() => setOpen({})}
      />
      {
        record && open.logs &&
        <Logs
          open={open.logs}
          task={record}
          dates={record.dates}
          result={record.result}
          targetList={record.params.targetList}
          onCancel={() => setOpen({})}/>
      }
    </div>
  )
}

export default Index
