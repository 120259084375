import React, { useState, useEffect, memo } from 'react'
import { Modal, Form, Input, Select, InputNumber, message, Row, Col } from 'antd'
import { typeLabel } from './index'
import { isMobile, requiredInputRule } from '@/utils/hooks'
import { Rule } from 'antd/es/form'
import { ValidateStatus } from 'antd/es/form/FormItem'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { getPaymentList, getPayments } from '@/store/slice/payment'
import { userExists } from '@/views/users/api'
import * as api from './api'

interface Props {
  open: boolean,
  types: Array<keyof typeof typeLabel>
  onCancel: () => void
  onSuccess?: () => void
}

const Index: React.FC<Props> = (props) => {
  const { open = false, types, onCancel, onSuccess } = props
  const [type, setType] = useState<keyof typeof typeLabel>()
  const [loading, setLoading] = useState<boolean>(false)
  const [validateStatus, setValidateStatus] = useState<Record<string, ValidateStatus>>({})

  const dispatch = useAppDispatch()
  const payments = useSelector(getPayments)

  const [form] = Form.useForm()
  const searchRule: Rule[] = [
    {
      message: '用户不存在',
      validator (rule: any, value) {
        if (!value) {
          setValidateStatus({ [rule.field]: '' })
          return Promise.resolve()
        }
        return getUserExists(rule.field, value)
      }
    }
  ]

  useEffect(toggleVisible, [dispatch, open])

  function toggleVisible () {
    if (open) {
      dispatch(getPaymentList())
    }
  }

  function resetForm () {
    form.resetFields()
    setType(undefined)
    setValidateStatus({})
  }

  // 通过用户名/uid查询数据id
  function getUserExists (field: string, value: number) {
    const params = {
      username: value,
      _isShowErrorTips: false
    }
    setValidateStatus({ [field]: 'validating' })
    return userExists(params).then(({ message, success, data }) => {
      if (success && data) {
        setValidateStatus({ [field]: 'success' })
      } else {
        setValidateStatus({ [field]: 'error' })
        return Promise.reject()
      }
    })
  }

  function onFinish (values: Record<string, any>) {
    const payment = payments.find(i => i._id === values.payment)
    values = {
      user: values.user,
      customer: values.customer,
      submitter: values.submitter,
      type: values.type,
      amount: values.amount,
      payment: {
        ...payment,
        receiptAccount: values.receiptAccount
      },
      voucher: values.voucher,
      remarks: values.remarks
    }
    setLoading(true)
    api.create(values).then(({ success, message: msg }) => {
      if (success) {
        onCancel()
        onSuccess && onSuccess()
        message.success(msg)
      }
      setLoading(false)
    })
  }

  return (
    <Modal
      open={open}
      title="创建账单"
      centered={isMobile}
      forceRender
      className="common-modal"
      maskClosable={false}
      confirmLoading={loading}
      afterClose={resetForm}
      onOk={() => form.submit()}
      onCancel={onCancel}>
      <Form
        name="createBill"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              name="user"
              label="用户"
              rules={[...requiredInputRule, ...searchRule]}
              required
              hasFeedback
              validateFirst
              validateStatus={validateStatus.user}
              validateTrigger={['onBlur']}>
              <Input maxLength={20} placeholder={'请输入'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="receiveUser"
              label="接收用户"
              rules={searchRule}
              hasFeedback
              validateStatus={validateStatus.receiveUser}
              validateTrigger={['onBlur']}>
              <Input maxLength={20} placeholder={'请输入'}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="type" label="账单类型" rules={requiredInputRule}>
              <Select
                placeholder="请选择"
                optionFilterProp="children"
                onChange={setType}>
                {
                  types.map(item => (
                    <Select.Option
                      key={item}
                      value={item}>
                      {typeLabel[item as keyof typeof typeLabel]}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="amount" label="金额" rules={requiredInputRule}>
              <InputNumber pattern="\d*" min={1} max={10000} placeholder="请输入"/>
            </Form.Item>
          </Col>
        </Row>
        {
          type &&
          <Form.Item name="payment" label="支付方式" rules={requiredInputRule}>
            <Select
              placeholder="请选择"
              optionFilterProp="children">
              {
                payments.map(item => (
                  <Select.Option
                    key={item._id}
                    value={item._id}>
                    {item.name}（{item.code}）
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        }
        {
          type && type === 'outflow' &&
          <Form.Item name={'receiptAccount'} label={'收款账号'}>
            <Input placeholder={'请输入'}/>
          </Form.Item>
        }
        <Form.Item name="voucher" label="付款凭证">
          <Input showCount maxLength={66} placeholder="请输入"/>
        </Form.Item>
        <Form.Item name="remarks" label="备注">
          <Input.TextArea rows={3} maxLength={100} showCount placeholder="请输入"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(Index, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
})
